import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
// import Divider from "@mui/material/Divider";
import { default as MuiDrawer } from "@mui/material/Drawer";
import List from "@mui/material/List";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemText from "@mui/material/ListItemText";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import moment from "moment";

import {
  AppFileHistorySidebar,
  SidebarArray,
  SidebarItem,
  SidebarVariables,
} from "./domain";
import AppData from "../AppData";
import { TabLinkType } from "../../pages/documentviewer/domain";
import { AggregatedPatentRespV1 } from "../../common/patent";
import { getSNLocale } from "../../common/sessionStorage";
import { Divider, ListItem } from "./ListItem";
// import { ListItem, ListItemIcon, Tooltip } from "@mui/material";

const drawerWidth = 262;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(58px + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(58px + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

/**
 * Capitalizes the provided name by just making the first character uppercase.
 * @param name
 * @returns
 */
function capitalizeName(name: string): string {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

/**
 * Creates a formatted one-line name using the provided values.
 * @param firstName
 * @param lastName
 * @param middleName
 */
function formatName(
  firstName: string,
  lastName: string,
  middleName?: string
): string {
  const formattedFirstName = capitalizeName(firstName);
  const formattedLastName = capitalizeName(lastName);

  if (middleName) {
    const formattedMiddleName = capitalizeName(middleName);
    return `${formattedFirstName} ${formattedMiddleName} ${formattedLastName}`;
  }

  return `${formattedFirstName} ${formattedLastName}`;
}

export type DrawerClickFunc = (
  menuData: SidebarItem,
  from?: string | undefined
) => void;

type PermanentDrawerLeftProps = {
  onClick: DrawerClickFunc;
  active: TabLinkType | "";
  application_num: string;
  patent_app_data: AggregatedPatentRespV1;
};

interface SideBarPatentInfo {
  application_num: string;
  filing_date: string;
  examiner_name: string;
  docket_num: string;
  first_named_author: string;
}

export default function Sidebar({
  onClick,
  active,
  application_num,
  patent_app_data,
}: PermanentDrawerLeftProps) {
  let navigate = useNavigate();
  const theme = useTheme();

  const [open, setOpen] = React.useState(true);
  const [locale] = React.useState<string>(getSNLocale());
  const [item, setItem] = React.useState<SideBarPatentInfo>({
    application_num: "",
    filing_date: "",
    examiner_name: "",
    docket_num: "",
    first_named_author: "",
  });

  // Load patent application info that is used for display.
  useEffect(() => {
    setItem({
      application_num: application_num || "N/A",
      filing_date: patent_app_data.patent.filing_date
        ? moment(patent_app_data.patent.filing_date)
            .locale(locale)
            .utc()
            .format("DD/MM/YYYY")
        : "N/A",
      examiner_name:
        formatName(
          patent_app_data.examiner_details.first_name,
          patent_app_data.examiner_details.last_name,
          patent_app_data.examiner_details.middle_name
        ) || "N/A",
      docket_num: patent_app_data.patent.docket_num || "N/A",
      first_named_author: patent_app_data.inventor_details.length
        ? formatName(
            patent_app_data.inventor_details[0].first_name,
            patent_app_data.inventor_details[0].last_name,
            patent_app_data.inventor_details[0].middle_name
          )
        : "N/A",
    });
  }, [application_num, patent_app_data, locale]);

  // Populate the data to render.
  const sidebarTextData = [
    { title: SidebarVariables.applicationNo, data: item.application_num },
    { title: SidebarVariables.filingDate, data: item.filing_date },
    { title: SidebarVariables.inventor, data: item.first_named_author },
    { title: SidebarVariables.attorneyDocketNo, data: item.docket_num },
  ];

  const renderIcon = (item: SidebarItem) => {
    return (
      <item.icon
        color="primary"
        fontSize="large"
        sx={{
          maxHeight: 25,
        }}
      />
    );
  };

  return (
    <Box
      className={"SideBar"}
      sx={{ display: "flex gap-4", position: "relative" }}
    >
      <CssBaseline />
      <Drawer
        variant="permanent"
        id="sidebar-drawer"
        open={open}
        sx={{ height: "100vh" }}
      >
        <DrawerHeader
          id="sidebar-drawer-header"
          sx={{ mt: 2, mb: 2, minHeight: 50, justifyContent: "center" }}
        >
          <Typography
            onClick={() => setOpen(!open)}
            variant="h4"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 400,
              color: theme.palette.primary.main,
            }}
          >
            {open ? "SENSUS" : "S"}
          </Typography>
        </DrawerHeader>
        <List disablePadding>
          <ListItem
            id="app-file-history-list-item"
            selected={AppFileHistorySidebar.id === active}
            open={open}
            onClick={() => {
              onClick(AppFileHistorySidebar);
            }}
            name={AppFileHistorySidebar.title}
            icon={renderIcon(AppFileHistorySidebar)}
          />
          {/* <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                onClick(AppFileHistorySidebar);
              }}
              sx={{
                px: open ? "auto" : 0,
                height: 40,
                minHeight: 40,
                justifyContent: open ? "initial" : "inherit",
                mx: 2.5,
                my: 1,
                borderRadius: 2,
                gap: 1.5,
                "&:hover": {
                  background: "rgba(0, 0, 0, 0.04)",
                  mx: 2.5,
                },
              }}
              selected={AppFileHistorySidebar.id === active}
            >
              <Tooltip
                title={AppFileHistorySidebar.title}
                placement="right"
                disableHoverListener={open}
              >
                {renderIcon(AppFileHistorySidebar)}
              </Tooltip>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <Typography variant="body2" color={theme.palette.primary.main}>{AppFileHistorySidebar.title}</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem> */}
        </List>
        <Divider variant="middle" light />
        {/* <Divider variant="middle" light sx={{
          color: "rgba(63, 81, 181, 0.08) !important",
          margin: "0 auto !important",
          width: "85% !important",
        }} /> */}
        <List disablePadding>
          {SidebarArray.map((item) => (
            <ListItem
              key={item.id}
              selected={item.id === active}
              open={open}
              name={item.title}
              icon={renderIcon(item)}
              onClick={() =>
                onClick({
                  id: item.id,
                  title: item.title,
                  icon: item.icon,
                  type: item.type,
                  from: "drawer",
                })
              }
            />
          ))}
          {/* <ListItem
            disablePadding
            sx={{
              display: "block",
            }}
          >
            {SidebarArray.map((item, index) => (
              <ListItemButton
                onClick={() => {
                  onClick({
                    id: item.id,
                    title: item.title,
                    icon: item.icon,
                    type: item.type,
                    from: "drawer",
                  })
                }}
                sx={{
                  px: open ? "auto" : 0,
                  height: 40,
                  minHeight: 40,
                  justifyContent: open ? "initial" : "inherit",
                  mx: 2.5,
                  my: 1,
                  borderRadius: 2,
                  gap: 1.5,
                  "&:hover": {
                    background: "rgba(0, 0, 0, 0.04)",
                    mx: 2.5,
                  },
                }}
                selected={item.id === active}
                key={index}
              >
                <Tooltip
                  title={item.title}
                  placement="right"
                  disableHoverListener={open}
                >
                  {renderIcon(item)}
                </Tooltip>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <Typography variant="body2" color={theme.palette.primary.main}>{item.title}</Typography>
                </ListItemText>
              </ListItemButton>
            ))}
          </ListItem> */}
        </List>
        {/* <Divider variant="middle" light sx={{
          color: "rgba(63, 81, 181, 0.08) !important",
          margin: "0 auto !important",
          width: "85% !important",
        }} /> */}
        <Divider variant="middle" light />
        <List>
          {open &&
            sidebarTextData.map((item, index) => (
              <AppData
                key={index}
                index={index}
                title={item.title}
                data={item.data}
              />
            ))}
          <ListItem
            id="docket-list-item"
            open={open}
            selected={false}
            onClick={() => {
              navigate("/docket");
              sessionStorage.clear();
            }}
            name="Back to Docket"
            icon={<ArrowBackIcon color="primary" sx={{ maxHeight: 25 }} />}
            nameStyles={{ textTransform: "uppercase" }}
            sx={{ mt: open ? 3 : 0 }}
          />
          {/* <ListItem
            id="docket-list-item"
            disablePadding
            sx={{
              display: "block",
              // mt: open ? 3 : 0,
              // width: "fit-content",
              // mx: open ? 0 : "auto",
            }}
          >
            <ListItemButton
              id="docket-list-item-button"
              onClick={() => {
                navigate("/docket");
                sessionStorage.clear();
              }}
              sx={{
                px: open ? "auto" : 0,
                height: 40,
                minHeight: 40,
                justifyContent: open ? "initial" : "inherit",
                mx: 2.5,
                my: 1,
                borderRadius: 2,
                gap: 1.5,
                "&:hover": {
                  background: "rgba(0, 0, 0, 0.04)",
                  mx: 2.5,
                },
              }}
            >
              <Tooltip
                title="Back to Docket"
                placement="right"
                disableHoverListener={open}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 2,
                    // ml: "auto",
                    // mr: "auto",
                    // justifyContent: open ? "inherit" : "center",
                  }}
                >
                  <ArrowBackIcon color="primary" fontSize="large" sx={{ maxHeight: 25 }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>
                <Typography variant="body2" color={theme.palette.primary.main} sx={{ textTransform: "uppercase" }}>{open ? "BACK TO DOCKET" : ""}</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem> */}
        </List>
        <PlayCircleIcon
          onClick={() => setOpen(!open)}
          sx={{
            position: "absolute",
            bottom: "45px",
            right: "4px",
            zIndex: 10000,
            cursor: "pointer",
            color: "rgba(0, 0, 0, 0.54) !important",
            transform: open ? "rotate(180deg)" : "",
          }}
        />
      </Drawer>
    </Box>
  );
}
