import "react-pdf/dist/esm/Page/TextLayer.css";

// import { modalData } from "../../common/commonData"
import CRS from "../../components/CRS";
import AppFileHistory from "../appFileHistory";
import Examiner from "../examiner";
import { TabLink, TabLinks } from "./domain";
import { OrganizedDocuments, SNDocument } from "../../common/document";
import FileDocument from "./filedocument";

/**
 * Wraps a section of documents. Each split pane will be its own DocumentWrapper.
 */
const DocumentWrapper = ({
  tabs,
  documents,
  isActive,
  handleDrawerClick,
  handleOpenDocument,
}: {
  tabs: TabLinks;
  documents: OrganizedDocuments;
  isActive: number;
  handleDrawerClick: (menuData: any, from?: string | undefined) => void;
  handleOpenDocument: (doc: SNDocument, from?: string) => void;
}) => {
  return (
    <div>
      {tabs.map((e: TabLink, index: number) => {
        // TODO: improve UX by keeping non-active tabs loaded.
        // if (index === isActive) {
        if (e.type === "app-file-history") {
          return (
            <div
              key={index}
              style={{ display: index === isActive ? "block" : "none" }}
            >
              <AppFileHistory
                key={`${e.type}_${index}`}
                documents={documents}
                handleOpenDocument={handleOpenDocument}
              />
            </div>
          );
        } else if (e.type === "claims") {
          return (
            <div
              key={index}
              style={{ display: index === isActive ? "block" : "none" }}
            >
              <CRS
                key={`${e.type}_${index}`}
                documents={documents.claims}
                handleOpenDocument={handleOpenDocument}
                title="Claims"
                document_type="claims"
              />
            </div>
          );
        } else if (e.type === "responses") {
          return (
            <div
              key={index}
              style={{ display: index === isActive ? "block" : "none" }}
            >
              <CRS
                key={`${e.type}_${index}`}
                documents={documents.responses}
                handleOpenDocument={handleOpenDocument}
                title="Responses"
                document_type="responses"
              />
            </div>
          );
        } else if (e.type === "specification") {
          return (
            <div
              key={index}
              style={{ display: index === isActive ? "block" : "none" }}
            >
              <CRS
                key={`${e.type}_${index}`}
                documents={documents.specification}
                handleOpenDocument={handleOpenDocument}
                title="Specification"
                document_type="specification"
              />
            </div>
          );
        } else if (e.type === "examiner") {
          return (
            <div
              key={index}
              style={{ display: index === isActive ? "block" : "none" }}
            >
              <Examiner
                key={`${e.type}_${index}`}
                handleDrawerClick={handleDrawerClick}
              />
            </div>
          );
        } else if (e.type === "figures") {
          return (
            <div
              key={index}
              style={{ display: index === isActive ? "block" : "none" }}
            >
              <CRS
                key={`${e.type}_${index}`}
                documents={documents.figures}
                handleOpenDocument={handleOpenDocument}
                title="Figures"
                document_type="figures"
              />
            </div>
          );
        }

        return (
          <div
            key={index}
            style={{ display: index === isActive ? "block" : "none" }}
          >
            <FileDocument
              key={`${e.type}_${index}`}
              documents={documents}
              e={e}
              index={index}
              handleOpenDocument={handleOpenDocument}
            />
          </div>
        );
      })}
    </div>
  );
};

export default DocumentWrapper;
