import { Grid, Link, Typography } from "@mui/material"

const Footer = () => {
    const getTodaysYear = (): number => {
        const d = new Date()
        return d.getFullYear()
    }

    return (
        <div>
            <Typography
                sx={{ m: 0, mt: 0, mb: 0 }}
                variant="body2"
                color="text.secondary"
                align="center"
                className="copyRightTypeography fs-12"
            >
                {` © ${getTodaysYear()} `}
                Sensus LLC
            </Typography>
            <Grid container sx={{ mt: 0 }}>
                <Grid
                    item
                    xs
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <Link
                        href="#"
                        variant="body2"
                        sx={{ mt: 1 }}
                        className="linkDecoNone fs-12"
                    >
                        Privacy Policy
                    </Link>
                    <Link
                        href="#"
                        variant="body2"
                        sx={{ mt: 1 }}
                        className="linkDecoNone fs-12"
                    >
                        Terms & Conditions
                    </Link>
                </Grid>
            </Grid>
        </div>
    )
}

export default Footer;