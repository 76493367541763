import React from "react";
import { styled, Theme, CSSObject, SxProps } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiDivider from "@mui/material/Divider";
import MuiTypography from "@mui/material/Typography";
import MuiListItem from "@mui/material/ListItem";
import MuiList from "@mui/material/List";
import {
  Container,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const List = styled(MuiList)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const Divider = styled(MuiDivider)(({ theme }) => ({
  marginTop: 10,
  marginBottom: 10,
  marginRight: 24,
  marginLeft: 24,
  color: "rgba(63, 81, 181, 0.08) !important",
  margin: "0 auto !important",
  width: "85% !important",
}));

/**
 * A text group in the sidebar for the patent application details.
 */
export const TextGroup = ({ title, text }: { title: string; text: string }) => {
  return (
    <Container
      disableGutters
      sx={{
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: 2,
        px: 2.5,
      }}
    >
      <TextGroupTitle>{title}</TextGroupTitle>
      <TextGroupText>{text}</TextGroupText>
    </Container>
  );
};

const TextGroupTitle = styled(MuiTypography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: "12px",
  fontFamily: theme.typography.fontFamily,
  fontSize: 12,
  color: "rgba(0, 0, 0, 0.6)",
  marginBottom: 4,
  marginLeft: 12,
  marginRight: 12,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

const TextGroupText = styled(MuiTypography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: "24px",
  fontFamily: theme.typography.fontFamily,
  fontSize: 16,
  color: "rgba(0, 0, 0, 0.87)",
  marginLeft: 12,
  marginRight: 12,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

interface ListItemProps {
  id?: string;
  sx?: SxProps<Theme>;
  open: boolean;
  onClick?: React.MouseEventHandler;
  name: string;
  icon: JSX.Element;
  nameStyles?: { [key: string]: any };
  selected: boolean;
}

/**
 * Styles the MUI ListItem component to standardize a sidebar icon button element.
 */
export const ListItem = ({
  id,
  sx,
  open,
  onClick,
  name,
  icon,
  nameStyles,
  selected,
}: ListItemProps) => {
  return (
    <MuiListItem
      disablePadding
      id={id}
      sx={Object.assign({ display: "block" }, sx)}
    >
      <ListItemButton
        onClick={onClick}
        selected={selected}
        sx={{
          px: open ? "auto" : 0,
          height: 40,
          minHeight: 40,
          minWidth: open ? "inherit" : "0px",
          justifyContent: open ? "initial" : "center",
          mx: open ? 2.5 : "8px",
          my: 1,
          borderRadius: 2,
          gap: 1.5,
          "&:hover": {
            background: "rgba(0, 0, 0, 0.04)",
            mx: open ? 2.5 : "8px",
          },
        }}
        className={open ? "" : "innerItemsCenter"}
      >
        <Tooltip title={name} placement={"right"} disableHoverListener={open}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              ml: open ? "auto" : "10px",
              justifyContent: "center",
            }}
          >
            {icon}
          </ListItemIcon>
        </Tooltip>
        <ListItemText sx={{ opacity: open ? 1 : 0, width: open ? "unset" : 0 }}>
          <MuiTypography variant="body2" color="primary" sx={nameStyles}>
            {name}
          </MuiTypography>
        </ListItemText>
      </ListItemButton>
    </MuiListItem>
  );
};
