import { Grid, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import graph from "../assets/graph.png";
import { IAppFileHistory } from "../common/index";

const Examiner = (props: any) => {
  let navigate = useNavigate();

  const redirect = (row: IAppFileHistory) => {
    navigate("/home");
  };

  return (
    <React.Fragment>
      <Box sx={{ p: 3, bgcolor: "background.default", minHeight: "100vh" }}>
        <Typography component="h3" variant="h6" className="examinerReport">
          SmartPat Patent Examiner Report
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          className="pageTitle examinerName"
        >
          Stanek, Kelsey L
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="examinerInnerBox">
              <Box sx={{ width: "100%" }}>
                <ListItemText className="listItemTitle">Art Unit</ListItemText>
              </Box>
              <ListItemText className="examinerData">AU 3771</ListItemText>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="examinerInnerBox">
              <Box sx={{ width: "100%" }}>
                <ListItemText className="listItemTitle">Center</ListItemText>
              </Box>
              <ListItemText className="examinerData">TC 3700</ListItemText>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="examinerInnerBox">
              <Box sx={{ width: "100%" }}>
                <ListItemText className="listItemTitle">
                  SmartPat Score
                </ListItemText>
              </Box>
              <ListItemText className="examinerData">38</ListItemText>
            </Box>
          </Grid>
        </Grid>

        <Box>
          <Box className="examinerInnerBoxBottom bottomBox">
            <ListItemText className="listItemTitleBottom">
              Allowance Rate Summary
            </ListItemText>
            <img src={graph} alt="graph" />
            <ListItemText className="w-62" sx={{ mt: 2 }}>
              Bad news: Examiner&apos;s allowance rate of 51% is below the
              average of 70% for art unit AU 3771
            </ListItemText>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Examiner;
