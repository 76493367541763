import {
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
} from '@mui/material'

import { DocketColumn } from '../../common';


interface TableHeaderProps {
    columns: DocketColumn[];
    hasMenuActions?: boolean;

    // Sort related props
    enableSort?: boolean;
    onRequestSort?: (property: string) => void;
    order?: 'asc' | 'desc';
    orderBy?: string;
}

function TableHeader({
    columns,
    hasMenuActions,
    enableSort = false,
    order,
    orderBy,
    onRequestSort,
}: TableHeaderProps) {
    const createSortHandler = (colId: string) => (event: React.MouseEvent<unknown, MouseEvent>) => {
        if (onRequestSort !== undefined) {
            onRequestSort(colId);
        }
    };

    return (
        <TableHead aria-label="table-head">
            <TableRow
                style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderBottom: 1,
                    borderRight: 0,
                }}
            >
                {columns.map((collData, collDataIdx) => (
                    <TableCell
                        className="text-nowrap tableHeader"
                        component="th"
                        scope="row"
                        align={collData.align || "left"}
                        // style={(idx === 0) ? { paddingLeft: 0, width: 'min-content' } : { width: 'min-content' }}
                        width={collData.width}
                        sortDirection={orderBy === collData.dataIndex ? order : false}
                        key={`${collData.title}_${collDataIdx}`}
                    >
                        {enableSort && collData.canSort &&
                            <Tooltip
                                title={order === 'asc' ? 'Ascending' : 'Descending'}
                                enterDelay={1000}
                                enterNextDelay={1000}
                                placement={collData.align && collData.align === 'right' ? 'bottom-start' : 'bottom-end'}
                            >
                                <TableSortLabel
                                    active={orderBy === collData.dataIndex}
                                    direction={order}
                                    onClick={createSortHandler(collData.dataIndex)}
                                >
                                    <Typography variant="inherit">
                                        {collData.renderTitle !== undefined ? collData.renderTitle(collData.title, collData.dataIndex) : collData.title}
                                    </Typography>
                                </TableSortLabel>
                            </Tooltip>}
                        {(!enableSort || (enableSort && !collData.canSort)) && (collData.renderTitle !== undefined ? collData.renderTitle(collData.title, collData.dataIndex) : collData.title)}
                    </TableCell>
                ))}
                {/* <TableCell padding="none"></TableCell> */}
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;