import axios from 'axios'

const API_URL = process.env.REACT_APP_API_SERVER_URL || "";

type ActivatedLinkType = "figure" | "patent" | "application" | "publication"

export interface ActivatedLink {
    // document_id is the document ID that this link resides in.
    document_id: string
    // reference_id is the reference to a unique identifier based on the activated link type.
    //
    // e.g. a patent link type reference ID would be the USPTO patent number.
    reference_id: string
    // type is the type of the activated link. Different types can be tracked by LinkType.
    type: ActivatedLinkType
    // page is the page of the document that this link is on.
    // Starts at 0 for the first page.
    page: number
    // text is the string representation of the activated link.
    // e.g. a patent application number would be "17176634".
    text: string
    publication_number: string[]
}

type ActivatedLinks = ActivatedLink[]

interface ActivatedLinksRespV1 {
    meta: any
    errors: string[]
    resource: ActivatedLinks
}

export async function getActivatedLinksForDocument(document_id: string): Promise<ActivatedLinks> {
    try {
        const resp = await axios.get(
            `${API_URL}/v1/activated-links/document/${document_id}`,
            { withCredentials: true },
        )
        const data: ActivatedLinksRespV1 = resp.data
        if (resp.status !== 200) {
            throw new Error(`request failed: ${data.errors.join("; ")}`)
        }
        return data.resource
    } catch (err) {
        console.log("error", err)
        throw new Error(`failed to get activated links for document(${document_id}): ${err}`)
    }
}