import React from 'react';
import { Navigate } from 'react-router-dom';

import { useUser } from '../userContext';


type PrivateRouteProps = {
    children: React.ReactNode;
};

export default function PrivateRoute({ children }: PrivateRouteProps) {
    const { user } = useUser()

    if (!user.isAuthenticated) {
        console.log(`User not authenticated, redirecting to /login`)
        return <Navigate to="/login" replace />;
    }
    return <>{children}</>;
}
