import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Card, CardContent } from "@mui/material";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

// TODO: this form needs to be changed to only email. The email will have a link to change password.
// New component needs to be added for that as well. Or,
// email input is added to login page, forgot password becomes the password reset page
export default function ForgotPassword() {
  let navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
    // TODO: forgot password needs to be implemented.
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          paddingTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" className="primaryColor">
          SENSUS
        </Typography>
        <Card
          variant="outlined"
          sx={{ borderRadius: "12px" }}
          className="cardStyle"
        >
          <CardContent>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 0, px: 3, py: 2 }}
            >
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                className="inputPadding"
                autoFocus
                variant="standard"
                autoComplete="off"
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                className="inputPadding"
                type="password"
                id="password"
                autoComplete="off"
                variant="standard"
              />
              <TextField
                margin="normal"
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                className="inputPadding"
                type="password"
                id="confirmPassword"
                autoComplete="off"
                variant="standard"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 0 }}
                className="buttonPrimary"
              >
                SUBMIT
              </Button>

              <Button
                style={{ textTransform: "capitalize", marginTop: "10px" }}
                variant="text"
                fullWidth
                onClick={() => navigate("/")}
                className="linkDecoNone forgetHover fs-14"
              >
                Login
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Typography
        sx={{ m: 0, mt: 0, mb: 0 }}
        variant="body2"
        color="text.secondary"
        align="center"
        className="copyRightTypeography  copyRightTypeographyForgot fs-12"
      >
        {" © "}
        2022 Sensus LLC
      </Typography>
      <Grid container sx={{ mt: 0 }}>
        <Grid
          item
          xs
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Link
            href="#"
            variant="body2"
            sx={{ mt: 1 }}
            className="linkDecoNone fs-12"
          >
            Privacy Policy
          </Link>
          <Link
            href="#"
            variant="body2"
            sx={{ mt: 1 }}
            className="linkDecoNone fs-12"
          >
            Terms & Conditions
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
}
