import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { SxProps, TablePagination, Theme } from "@mui/material";
import TableHeader from "./TableHeader";
import { sortDataSource } from "./sort"

interface DataTableProps {
  columns: any[];
  dataSource: any[];
  sx?: SxProps<Theme>;
  showHeader?: boolean;
  pagination?: boolean;
  enableSort?: boolean;
  defaultOrder?: 'desc' | 'asc';
  defaultOrderBy?: string;
  defaultRowsPerPage?: number;
}

export default function DataTable({
  columns,
  dataSource,
  sx,
  showHeader,
  pagination,
  enableSort = false,
  defaultOrder = 'desc',
  defaultOrderBy,
  defaultRowsPerPage,
}: DataTableProps) {

  // Paging
  // TODO: we may eventually want to bake paging into the query
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage || 5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Sorting
  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);

  const handleRequestSort = (colId: string) => {
    const isAsc = orderBy === colId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(colId)
  }

  // Data
  let data = React.useMemo(() => {
    if (enableSort && order && orderBy) {
      return sortDataSource(dataSource, order, orderBy);
    } else {
      return dataSource;
    }
  }, [dataSource, enableSort, order, orderBy]);

  data = React.useMemo(() => {
    if (pagination && data && data !== undefined) {
      return data.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    } else {
      return data;
    }
  }, [data, page, rowsPerPage, pagination])

  return (
    <TableContainer component={Paper}>
      <Table className="min-auto" sx={sx} aria-label="a dense table">
        {(showHeader) && (
          <TableHeader
            columns={columns}
            enableSort={enableSort}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
        )}
        <TableBody>
          {data.map((dataSource: any, index: number) => {
            return (
              <TableRow
                key={`q_${dataSource.title}_${index}`}
                className="tableCell"
                style={{
                  borderTop: 0,
                  borderLeft: 0,
                  borderBottom: 1,
                  borderRight: 0,
                }}
                hover
              >
                {columns.map((collData: any, iindex: number) => {
                  return (
                    <TableCell
                      align="left"
                      key={`${index}_${iindex}`}
                      scope="row"
                      {...(collData.onClick && {
                        style: { cursor: "pointer" },
                        onClick: () => collData.onClick(dataSource),
                      })}
                      width={collData.width}
                      className="tableData tableCell"
                    >
                      {collData.render(dataSource, index)}
                    </TableCell>

                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataSource.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
}
