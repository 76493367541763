import { TabLinkType } from "../../pages/documentviewer/domain";
// import claims from "../../assets/svgs/claims.svg";
// import examiner from "../../assets/svgs/examiner.svg";
// import officeAction from "../../assets/svgs/officeAction.svg";
// import responses from "../../assets/svgs/responses.svg";
// import specification from "../../assets/svgs/specification.svg";
import PhotoIcon from "@mui/icons-material/Photo";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import {
  ChatBubble,
  Description,
  DocumentScanner,
  Help,
  ListAlt,
} from "@mui/icons-material";

export interface SidebarItem {
  id: string;
  title: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  type: TabLinkType;
  from?: string;
}

export const AppFileHistorySidebar: SidebarItem = {
  id: "app-file-history",
  title: "Application File History",
  // icon: appFile,
  icon: ListAlt,
  type: "app-file-history",
  from: "drawer",
};

export const SidebarArray: SidebarItem[] = [
  {
    id: "office-action",
    title: "Office Action",
    icon: DocumentScanner,
    type: "pdf",
  },
  {
    id: "claims",
    title: "Claims",
    icon: Help,
    type: "claims",
  },
  {
    id: "responses",
    title: "Responses",
    icon: ChatBubble,
    type: "responses",
  },
  {
    id: "specification",
    title: "Specification",
    icon: Description,
    type: "specification",
  },
  {
    id: "figures",
    title: "Figures",
    icon: PhotoIcon,
    type: "figures",
  },
  // {
  //     id: "examiner",
  //     title: "Examiner",
  //     icon: examiner,
  //     type: "examiner",
  // },
];

export const SidebarVariables = {
  applicationNo: "Application No.",
  filingDate: "Filing Date",
  inventor: "First Named Inventor",
  attorneyDocketNo: "Attorney Docket No.",
};
