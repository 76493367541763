import axios from "axios";

import { AggregatedPatentRespV1, PatentAppResponseV1 } from "../common/patent";
import { SNDocument } from "../common/document";

const API_URL = process.env.REACT_APP_API_SERVER_URL || "";


/**
 * Retrieves a patent application's aggregated data from the backend.
 * @param application_num
 * @returns
 */
export async function getPatentAppData(application_num: string): Promise<AggregatedPatentRespV1> {
    try {
        const resp = await axios.get(
            `${API_URL}/v1/patents/application/${application_num}/aggregated`,
            { withCredentials: true },
        );
        const data: PatentAppResponseV1 = resp.data
        if (resp.status !== 200) {
            throw new Error(`patent application request failed: ${data.errors.join("; ")}`)
        }
        return data.resource
    } catch (err) {
        console.log("error", err);
        throw new Error(`failed to get aggregated application data: ${err}`)
    }
}

// ApplicationDocumentRespV1 is the response object we expect from retrieving an application.
export interface ApplicationDocumentRespV1 {
    meta: any
    errors: string[]
    resource: SNDocument[] | undefined
}

/**
 * Retrieves a patent application's documents for their file history.
 * @param application_num
 * @returns
 */
export async function getPatentAppDocuments(application_num: string): Promise<SNDocument[] | undefined> {
    let obj: ApplicationDocumentRespV1
    try {
        var resp = await axios.get(`${API_URL}/v1/documents/application/${application_num}`, {
            headers: {},
            params: {
            },
            withCredentials: true,
            timeout: 5000,
        })
        obj = resp.data
        if (resp.status !== 200) {
            throw new Error(`request failed: ${obj.errors.join("; ")}`)
        }
        return obj.resource
    } catch (err) {
        console.error(`failed to get application: ${err}`)
        throw new Error(`Failed to retrieve document data...`)
    }
}
