import DataTable from "./Table/Table";
import { IconButton, Paper, TableContainer, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Box } from "@mui/system";

export default function PopupModal({
  icon,
  title,
  subSection1,
  subSection2,
  open,
  onClose,
  table1data,
  table2data,
  columns,
}: {
  open: boolean;
  icon: any | undefined;
  title: string | undefined;
  subSection1: string | undefined;
  subSection2: string | undefined;
  onClose: React.MouseEventHandler<HTMLButtonElement> | undefined;
  table1data: any[] | undefined;
  table2data: any[] | undefined;
  columns:
    | (
        | {
            title: string;
            dataIndex: string;
            render: (item: any) => JSX.Element;
          }
        | {
            title: string;
            dataIndex: string;
            render: (item: any) => any;
          }
      )[]
    | undefined;
}) {
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose} maxWidth="md">
      <DialogTitle id="responsive-dialog-title" className="dflexSpaceBetween">
        <Box className="dflexCenter" sx={{ gap: 1 }}>
          {icon}
          {title}
        </Box>
        <Box>
          <IconButton
            className="curserPointer"
            size="small"
            style={{ marginLeft: 8 }}
            onClick={onClose}
          >
            <CloseSharpIcon fontSize="small" style={{ margin: 0 }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box sx={{ padding: 2 }}>
        <Typography sx={{ paddingTop: 0 }}>{subSection1}</Typography>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          {columns &&
            columns !== undefined &&
            table1data &&
            table1data !== undefined && (
              <DataTable
                sx={{ minWidth: 550, px: 2 }}
                columns={columns}
                dataSource={table1data}
                showHeader={false}
              />
            )}
        </TableContainer>
        <Typography sx={{ paddingTop: 0, mt: 2 }}>{subSection2}</Typography>
        <TableContainer component={Paper} sx={{ my: 2 }}>
          {columns &&
            columns !== undefined &&
            table2data &&
            table2data !== undefined && (
              <DataTable
                sx={{ minWidth: 550, px: 2 }}
                columns={columns}
                dataSource={table2data}
                showHeader={false}
              />
            )}
        </TableContainer>
      </Box>
    </Dialog>
  );
}
