import { Box, Button, Tooltip } from "@mui/material";
import { useState } from "react";
import { getJobStatus, uploadCustomDocket } from "../api/patents";
import { CustomPatent, Patent } from "../common/patent";
import ReplayIcon from "@mui/icons-material/Replay";

const StatusComponent = (props: { item: CustomPatent; onSuccess: any }) => {
  const { item, onSuccess } = props;
  const [currentPNL, setCurrentPNL] = useState<{
    status: number;
    failedReason: string;
    requestId: string;
  }>({ status: 0, failedReason: "", requestId: "" });
  const [show, setShow] = useState<boolean>(false);

  const tooltipContent = (
    <div>
      <div>
        {currentPNL?.failedReason ? "Error: Job Failed" : "Job Completed"}
      </div>
      <div>
        {currentPNL?.failedReason 
          ? `Please try again or contact support with request ID: ${currentPNL.requestId}`
          : "Completed"}
      </div>
    </div>
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip
        title={currentPNL.requestId ? tooltipContent : ""}
        placement={"right"}
      >
        <Button
          color={
            currentPNL && currentPNL.status === 200
              ? "success"
              : currentPNL && currentPNL.status === 500
              ? "error"
              : "primary"
          }
          onClick={async () => {
            await getJobStatus(item?.JobIds, item?.RequestIds).then(
              (e: any) => {
                setCurrentPNL(e);
                if (e.status === 500) {
                  setShow(true);
                }
              }
            );
          }}
        >
          {currentPNL && currentPNL.status === 200
            ? "Success"
            : currentPNL && currentPNL.status && currentPNL.status === 500
            ? "Failed"
            : "Get"}
        </Button>
      </Tooltip>

      {show && (
        <Box
          onClick={async () => {
            await uploadCustomDocket({ nums: item.application_num! }, onSuccess,true)
          }}
        >
          <ReplayIcon sx={{ cursor: 'pointer', color: 'black' }} />
        </Box>
      )}
    </Box>
  );
};

export default StatusComponent;
