import moment from 'moment';


export function sortDataSource(
    data: Array<{ [key: string]: any }>,
    sortOrder: 'asc' | 'desc',
    sortBy: string,
) {
    return [...data].sort((a, b) => {
        const valueA = a[sortBy];
        const valueB = b[sortBy];

        const dateA = moment.utc(valueA);
        const dateB = moment.utc(valueB);
        if (dateA.isValid() && dateB.isValid()) {
            if (dateA.isBefore(dateB)) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (dateA.isAfter(dateB)) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        }

        if (valueA < valueB) {
            return sortOrder === 'asc' ? -1 : 1;
        } else if (valueA > valueB) {
            return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
    });
}
