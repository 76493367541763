import { References } from "./references";

// SNDocument is any USPTO document as represented by our backend.
// If anything changes on the Document schema in the backed, it should also change here.
export interface SNDocument {
  id: string;
  created_at: string;
  document_code: string;
  file_location: string;
  mime_type: string;
  patent_application_number: string;
  patent_number: string;
  submission_date: string;
  updated_at: string;
  uspto_document_id: string;
  document_description: string;
  publication_number?: string
  page_count: number;
  // The document references found in the current document.
  // These are typically activated links.
  references?: References;
}

/**
 * Represents an array of SNDocument objects.
 */
export type SNDocuments = SNDocument[];

export interface OrganizedDocuments {
  // All office actions
  office_actions: SNDocument[];
  // Claims related documents
  claims: SNDocument[];
  // Examiner responses
  responses: SNDocument[];
  // Specification related documents
  specification: SNDocument[];
  // Figures associated with the patent app
  figures: SNDocument[];
  // All other documents we have not grouped
  other: SNDocument[];
}

export const document_code_map = {
  office_action: new Set(["CTNF", "CTFR"]),
  claims: new Set(["CLM"]),
  responses: new Set(["REM"]),
  specification: new Set(["SPEC"]),
  figures: new Set(["DRW", "DRW.NONBW"]),
};

export const document_codes = {
  ABST: "abstract",
  CLM: "claims",
  DRW: "drawings",
  "DRW.NONBW": "Drawings - other than black and white line drawings",
  SPEC: "specification",
  CTNF: "non - final rejection",
  CTFR: "Final Rejection",
  ABN: "abandonment",
  REM: "applicant arguments / remarks",
  "A.PE": "preliminary amendment",
  FOR: "foreign reference",
  IDS: "info disclosure statement form",
  CTEQ: "ex parte quayle action",
  "A.QU": "response after ex parte quayle action",
  NOA: "notice of allowance and fees due",
  NPL: "Non patent literature",
  "A…": "amendment / request for reconsideration - after non - final rejection",
  RCEX: "request for continued examination",
  EXIN: "examiner interview summary record",
  "INTRVIEW.APP": "applicant summary of interview with examiner",
  "ELC.": "response to election / restriction filed",
};

/**
 * Organizes the documents retrieved from the server into a format that is more convenient for the app.
 * The documents within each group are always sorted into descending order by submissino date.
 * @param documents
 * @returns
 */
export function OrganizeDocuments(documents: SNDocument[]): OrganizedDocuments {
  const orgDocs: OrganizedDocuments = {
    office_actions: [],
    claims: [],
    responses: [],
    specification: [],
    figures: [],
    other: [],
  };

  // Pre-sort by submission date
  const docs = documents.sort((a, b) => {
    // Default to sort descending.
    const valA = a.submission_date;
    const valB = b.submission_date;
    if (valA < valB) {
      return 1;
    } else if (valA > valB) {
      return -1;
    }
    return 0;
  });
  // Do the organization
  for (const doc of docs) {
    if (document_code_map.office_action.has(doc.document_code)) {
      orgDocs.office_actions.push(doc);
    } else if (document_code_map.claims.has(doc.document_code)) {
      orgDocs.claims.push(doc);
    } else if (document_code_map.responses.has(doc.document_code)) {
      orgDocs.responses.push(doc);
    } else if (document_code_map.specification.has(doc.document_code)) {
      orgDocs.specification.push(doc);
    } else if (document_code_map.figures.has(doc.document_code)) {
      orgDocs.figures.push(doc);
    } else {
      orgDocs.other.push(doc);
    }
  }
  return orgDocs;
}

/**
 * Converts the organized documents back into an array of all documents retrieved from the server.
 * The documents are always sorted into descending order by submission date.
 * @param documents
 * @returns
 */
export function OrganizedDocumentsToAll(
  documents: OrganizedDocuments
): SNDocument[] {
  const mergedSlice: SNDocument[] = [];
  mergedSlice.push(...documents.office_actions);
  mergedSlice.push(...documents.claims);
  mergedSlice.push(...documents.responses);
  mergedSlice.push(...documents.specification);
  mergedSlice.push(...documents.figures);
  mergedSlice.push(...documents.other);
  return mergedSlice.sort((a, b) => {
    // Default to sort descending.
    const valA = a.submission_date;
    const valB = b.submission_date;
    if (valA < valB) {
      return 1;
    } else if (valA > valB) {
      return -1;
    }
    return 0;
  });
}

/**
 * Gets all documents that are of the same document code and returns them in descending order by submission date.
 * @param documents
 * @param code
 * @returns
 */
export function GetDocumentsForCode(
  documents: OrganizedDocuments,
  code: string
): SNDocument[] {
  const sorted = OrganizedDocumentsToAll(documents);
  return sorted.filter((doc) => doc.document_code === code);
}
