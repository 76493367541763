import { TabLinks } from "../pages/documentviewer/domain"
import { AggregatedPatentRespV1 } from "./patent"

export const setApplicationNumber = (num: string) => {
    sessionStorage.setItem("application_number", num)
}

export const getApplicationNumber = (): string => {
    return sessionStorage.getItem("application_number") || ""
}

/**
 * Sets the provided aggregate patent application data to session storage.
 * @param p
 */
export const setCurrentPatent = (p: AggregatedPatentRespV1) => {
    sessionStorage.setItem("current_patent", JSON.stringify(p))
}

export const getCurrentPatent = (): AggregatedPatentRespV1 => {
    const p = sessionStorage.getItem("current_patent") || "{}"
    return JSON.parse(p)
}

export const setSNLocale = (locale: string) => {
    sessionStorage.setItem("sn_locale", locale)
}

export const getSNLocale = (): string => {
    return sessionStorage.getItem("sn_locale") || "en-US"
}

export const setLeftPane = (tabs: TabLinks) => {
    sessionStorage.setItem("array1", JSON.stringify(tabs))
}

export const getLeftPane = (): TabLinks => {
    const tl = sessionStorage.getItem("array1") || "[]"
    return JSON.parse(tl)
}

export const setRightPane = (tabs: TabLinks) => {
    sessionStorage.setItem("array2", JSON.stringify(tabs))
}

export const getRightPane = (): TabLinks => {
    const tl = sessionStorage.getItem("array2") || "{}"
    return JSON.parse(tl)
}