import React, { useState } from "react";
import { getMe } from "../api/auth";

const localStorageKey = "sensus_session_data";

interface User {
  id?: string;
  email?: string;
  customer_id?: string;
  registration_number?: string;
  docket_numbers?: string[];
  customer_numbers?: string[];
}

export interface UserSession extends User {
  isAuthenticated: boolean;
}

interface ProvidedValue {
  user: UserSession;
  setUser: (user: UserSession) => void;
  loading: boolean;
}

const UserContext = React.createContext<ProvidedValue>({
  user: {
    isAuthenticated: false,
    id: "",
    email: "",
    customer_id: "",
    registration_number: "",
    docket_numbers: [],
  },
  setUser: (user: UserSession) => {},
  loading: true,
});

// Provides a custom version of React.useState to handle any session management.
function useCustomSetUserState(
  initialState: UserSession
): [UserSession, React.Dispatch<React.SetStateAction<UserSession>>] {
  const [state, setState] = React.useState(initialState);

  const customSetState = (
    value: UserSession | ((prevState: UserSession) => UserSession)
  ) => {
    if (typeof value === "function") {
      setState((prevState) => {
        const newValue = value(prevState);
        localStorage.setItem(localStorageKey, JSON.stringify(newValue)); // store in localStorage
        return newValue;
      });
    } else {
      localStorage.setItem(localStorageKey, JSON.stringify(value)); // store in localStorage
      setState(value);
    }
  };
  return [state, customSetState];
}

export interface UserProviderProps {
  children?: React.ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  let initialState: UserSession = {
    isAuthenticated: false,
    id: "",
    email: "",
    customer_id: "",
    registration_number: "",
    docket_numbers: [],
  };

  const [user, setUser] = useCustomSetUserState(initialState);
  const [loading, setLoading] = useState(true);

  const verifyUser = async () => {
    try {
      const data = await getMe();
      if (data) {
        setUser(data);
      } else {
        setUser({
          isAuthenticated: false,
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(`failed to get user data: ${JSON.stringify(err)}`);
      setUser({
        isAuthenticated: false,
      });
    }
  };

  // We disabled dependency lint for useEffect, because including it causes an infinite loop
  React.useEffect(() => {
    verifyUser();
  }, []); // eslint-disable-line

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const ClearUser = () => {
  localStorage.removeItem(localStorageKey);
};

export const useUser = () => React.useContext(UserContext);
