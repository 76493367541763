import axios from 'axios'
import Papa from 'papaparse'
import { ApplicationDocumentRespV1 } from './document'

const API_URL = process.env.REACT_APP_API_SERVER_URL || "";

export type UploadDocketRequestV1 = {
    files?: File[]
    fileField?: string
    nums?: string
}

type UploadDocketResponseV1 = {
    meta: any
    errors: string[]
}

/**
 * Uploads the provided docket in either file or
 * @param input
 */
export async function uploadCustomDocket(input: UploadDocketRequestV1,onSuccess: (jobIds:string[]) => void,retry: boolean): Promise<void> {
    try {
        // multi-part form
        const formData = new FormData();
        // Add num field to form data
        if (input.nums && input.nums.length) {
            let sp = input.nums.split(",")
            if (sp.length <= 1) {
                sp = input.nums.split(/\r?\n|\r/)
            }
            for (let s of sp) {
                formData.append('num', s)
            }
        }
        // Add files to form data
        if (input.files && input.files.length) {
            input.files.forEach((file) => {
                formData.append(`${file.name}`, file)
            })
        }
        if (input.fileField) {
            formData.append("file_field", input.fileField)
        }
        if(retry){
          formData.append("retry", "true")
        }
        const resp = await axios.post(`${API_URL}/v1/docket`, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        const data: UploadDocketResponseV1 = resp.data
        if (resp.status !== 200) {
            if (data.errors && data.errors.length) {
                throw new Error(`upload docket request failed: ${data.errors.join("; ")}`)
            }
            throw new Error(`upload docket request failed with status ${resp.status}`)
        }else {
          if( resp && resp.data.jobInfos && resp.data.jobInfos.length > 0){
            const jobIDs = resp.data.jobInfos.map((job:{AppNum:string,JobID:string}) => job.JobID);
          // Store new jobIds in localStorage
          localStorage.setItem('jobIds', JSON.stringify(jobIDs));
          onSuccess(jobIDs);
          }
        }
        return 
           
    } catch (err) {
        console.warn("error", err)
        throw new Error(`failed to upload docket data: ${err}`)
    }
}

// ApplicationDocumentRespV1 is the response object we expect from retrieving an application.
export interface ApplicationJobPolling {
    meta: any
    errors: string[]
    resource: { appNum: string; progress: number }[] | undefined
  }
  
  /**
   * Retrieves a patent application's documents for their file history.
   * @param application_num
   * @returns
   */
  export async function getJobPolling(jobIds:string[]): Promise<
    { appNum: string; progress: number; jobId: string }[] | undefined
  > {
    let obj: ApplicationJobPolling
    try {
      var resp = await axios.post(`${API_URL}/v1/crawler/job/polling`,{jobIds}, {
        headers: {},
        withCredentials: true,
        timeout: 5000,
      })
      obj = resp.data
      if (resp.status !== 200) {
        throw new Error(`request failed: ${obj.errors.join('; ')}`)
      }
      return resp.data
    } catch (err) {
      console.error(`failed to get application: ${err}`)
      throw new Error(`Failed to retrieve job polling data...`)
    }
  }
  
  /**
   * Retrieves a patent application's documents for their file history.
   * @param application_num
   * @returns
   */
  export async function getJobStatus(
    jobId: string | undefined,
    request_id: string | undefined
  ): Promise<{ status: string }[] | undefined> {
    let obj: ApplicationJobPolling
    try {
      var resp = await axios.get(
        `${API_URL}/v1/crawler/job_status/status/${jobId}`,
        {
          headers: {
            'X-Request-Id': request_id
          },
          withCredentials: true,
          timeout: 5000,
        }
      )
      obj = resp.data
      if (resp.status !== 200) {
        throw new Error(`request failed: ${obj.errors.join('; ')}`)
      }
      return resp.data
    } catch (err) {
      console.error(`failed to get application: ${err}`)
      throw new Error(`Failed to retrieve job status...`)
    }
  }