import React, { useCallback, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Alert, IconButton, Snackbar, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";

import Sidebar from "../../components/Sidebar/Sidebar";
import Split from "../../components/Split";
import DocumentWrapper from "./documentwrapper";
import { TabLink, TabLinkType, TabLinks } from "./domain";
import { useNavigate } from "react-router-dom";
import { OrganizedDocuments, SNDocument } from "../../common/document";
import {
  getLeftPane,
  getRightPane,
  getSNLocale,
  setLeftPane,
  setRightPane,
} from "../../common/sessionStorage";
import { AggregatedPatentRespV1 } from "../../common/patent";
import { SidebarItem } from "../../components/Sidebar/domain";
import { DrawerClickFunc } from "../../components/Sidebar/Sidebar";

const createDocumentTitle = (document: SNDocument, locale: string): string => {
  return `${document.document_code}_${moment(document.submission_date)
    .locale(locale)
    .utc()
    .format("DD/MM/YYYY")}`;
};

/**
 * Converts an SNDocument into the TabLink to display.
 * @param title
 * @param type
 * @param document
 * @returns
 */
const toTabLink = (
  locale: string,
  type: TabLinkType,
  document: SNDocument
): TabLink => {
  return {
    id: document.id,
    title: createDocumentTitle(document, locale),
    type,
    document,
  };
};

// TODO: fix tab active highlighting
/**
 * Entrypoint for viewing documents in the app, such as office actions.
 */
const DocumentViewer = ({
  application_num,
  patent_app_data,
  documents,
}: {
  application_num: string;
  patent_app_data: AggregatedPatentRespV1;
  documents: OrganizedDocuments;
}) => {
  let navigate = useNavigate();
  const [locale] = useState(getSNLocale());

  useEffect(() => {
    if (!application_num) {
      // If there's no app num then we don't know what to display.
      // Therefore, redirect to docket to have user choose.
      navigate("/docket");
    }
  }, [application_num, navigate]);

  // Either pull the state stored to session storage or default to the first (most recent) office action.
  // We convert the SNDocument into a TabLink.
  const [array1, setArray1] = useState<TabLinks>([]);
  const [array2, setArray2] = useState<TabLinks>([]);

  // Tracks the active tab index for the left pane
  const [active, setActive] = useState(0);
  // Tracks the active tab index for the right pane
  const [active2, setActive2] = useState(0);
  // Tracks which sidebar tab is active
  const [activeMenu, setActiveMenu] = useState<TabLinkType | "">("pdf");
  // Tracks whether a tab drag was started.
  const [trackDrag, setTrackDrag] = useState(false);
  // Set an alert if the sidebar is clicked, but there are no documents
  const [sidebarNoData, setSidebarNoData] = useState(false);

  /**
   * Saves array1 to session storage and tracks which tab is active.
   * @param dataArray
   */
  const set_Array1 = useCallback(
    (dataArray: TabLinks) => {
      setArray1(dataArray);
      setLeftPane(dataArray);
      if (dataArray[active]) {
        setActiveMenu(dataArray[active].type);
      } else {
        setActiveMenu("");
      }
    },
    [active]
  );

  /**
   * Run when the split button is clicked to create a right and left pane.
   */
  const onSplit = () => {
    setArray2([array1[active]]);
  };

  /**
   * Dedups the tabs when unsplitting the left/right panes.
   */
  const onUnSplit = () => {
    let newArray1 = array1;
    array2.forEach((e: TabLink) => {
      if (array1.filter((i: TabLink) => i.id === e.id).length === 0) {
        newArray1.push(e);
      }
    });
    set_Array1(newArray1);
    setArray2([]);
    setActive2(0);
  };

  /**
   * Handles logic to reorder the left or right pane array
   * @param list
   * @param startIndex
   * @param endIndex
   * @returns
   */
  const reorder = (list: TabLinks, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getList = (id: string) => {
    if (id === "list1") {
      return array1;
    }
    return array2;
  };

  const move = (
    source: TabLinks,
    destination: TabLinks,
    droppableSource: any,
    droppableDestination: any
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed]: any[] = sourceClone.splice(droppableSource.index, 1);
    const result: any = {
      isExistInIndex: -1,
    };

    if (destClone.filter((e: TabLink) => e.id === removed.id).length === 0) {
      destClone.splice(droppableDestination.index, 0, removed);
    } else {
      result.isExistInIndex = destClone.findIndex(
        (e: TabLink) => removed.type === "pdf" && e.id === removed.id
      );
    }
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  const getListStyle = (isDraggingOver: boolean, isEmpty: boolean) => ({
    background: isDraggingOver ? "lightblue" : "#F6F6FC",
    display: "flex",
    width: "100%",
    // padding: grid,
    overflow: "auto",
    // minHeight: isEmpty ? "45px" : "NaN",
    marginTop: "10px",
  });

  const handleDragEnd = (result: DropResult) => {
    setTrackDrag(false);
    let { source } = result;
    let { destination } = result;

    if (source?.droppableId === "list3") {
      source.droppableId = "list1";
    }
    if (source?.droppableId === "list4") {
      source.droppableId = "list2";
    }

    if (destination?.droppableId === "list3") {
      destination.droppableId = "list1";
    }
    if (destination?.droppableId === "list4") {
      destination.droppableId = "list2";
    }

    if (destination?.droppableId === "list5") {
      destination.droppableId = "list2";
      destination.index = 0;
    }

    if (active > 0 && source?.droppableId === "list1") {
      if (source?.index === array1.length - 1 || source?.index < active) {
        setActive(active - 1);
      }
    }
    if (active2 > 0 && source?.droppableId === "list2") {
      if (source?.index === array2.length - 1 || source?.index < active2) {
        setActive2(active2 - 1);
      }
    }

    if (source?.droppableId === destination?.droppableId) {
      const items = reorder(
        getList(source?.droppableId || ""),
        source?.index,
        destination?.index
      );
      if (source?.droppableId === "list1") {
        set_Array1(items);
      }
      if (source?.droppableId === "list2") {
        setArray2(items);
      }

      if (destination?.droppableId === "list1") {
        setActive(destination.index);
      }
      if (destination?.droppableId === "list2") {
        setActive2(destination.index);
        // setActive(destination.index - 1);
      }
    } else {
      const result = move(
        getList(source?.droppableId || ""),
        getList(destination?.droppableId || ""),
        source,
        destination
      );
      if (result.list1) {
        set_Array1(result.list1);
      }
      if (result.list2) {
        setArray2(result.list2);
      }

      if (
        result.isExistInIndex === -1 &&
        destination?.droppableId === "list1"
      ) {
        setActive(destination.index);
      }
      if (
        result.isExistInIndex === -1 &&
        destination?.droppableId === "list2"
      ) {
        setActive2(destination.index);
      }
    }
  };

  useEffect(() => {
    if (getLeftPane().length) {
      set_Array1(getLeftPane());
    } else if (documents.office_actions.length) {
      set_Array1([toTabLink(locale, "pdf", documents.office_actions[0])]);
    }
  }, [locale, documents.office_actions, active, set_Array1]);

  useEffect(() => {
    if (getRightPane().length) {
      setArray2(getRightPane());
    }
  }, []);

  useEffect(() => {
    if (array1.length === 0 && array2.length > 0) {
      // array1 is empty, so move array2 to become array1
      setLeftPane(array2);
      setRightPane([]);
      set_Array1(array2);
      setArray2([]);
      setActive(active2);
      setActive2(0);
    }
  }, [array1, array2, active2, set_Array1]);

  useEffect(() => {
    setRightPane(array2);
  }, [array2]);

  /**
   * Contains the logic to add and focus a new tab or just focus an existing tab in the pane specified by `from`.
   * One thing to note is that if both panes are open, new tabs will open on the opposite pane.
   * @param link the TabLink data to match or add with existing tabs
   * @param from specify which pane to manage
   */
  const manageTabs = useCallback(
    (link: TabLink, from?: string, type?: string) => {
      if (!link || link === undefined || link.id === "") {
        return;
      }

      let to = from;
      // Manipulate `from` to add to opposite pane if both are open.
      if (from === "" || from === "array1" || from === undefined) {
        if (type === "document") {
          to = "array2";
        }
      } else if (from === "array2") {
        to = "array1";
      }
      if (from === "modal") {
        to = "array2";
      }
      if (to === "array2") {
        if (
          array2.filter((e: TabLink) => e.id === link.id).length === 0 ||
          array2.length === 0
        ) {
          array2.push(link);
          setArray2(JSON.parse(JSON.stringify(array2)));
          setActive2(array2.length - 1);
        } else if (link.id !== "") {
          setActive2(array2.findIndex((e: TabLink) => e.id === link.id));
        }
      } else {
        // Check if the selected menu item is in the left pane.
        if (array1.filter((e: TabLink) => e.id === link.id).length === 0) {
          // Not in pane, so add.
          array1.push(link);
          set_Array1(array1);
          setActive(array1.length - 1);
        } else if (link.id !== "") {
          // In pane so focus it.
          setActive(array1.findIndex((e: TabLink) => e.id === link.id));
        }
      }
    },
    [array1, array2, set_Array1]
  );

  /**
   * Handles when an item in the sidebar is clicked.
   * @param menuData
   * @param from
   */
  const handleDrawerClick: DrawerClickFunc = (
    menuData: SidebarItem,
    from?: string | undefined
  ) => {
    let link: TabLink = {
      id: "",
      title: "",
      type: "pdf",
    };

    if (
      menuData.type === "app-file-history" ||
      menuData.type === "claims" ||
      menuData.type === "responses" ||
      menuData.type === "examiner" ||
      menuData.type === "figures" ||
      menuData.type === "specification"
    ) {
      // These are not individual documents so we do not have a document ID to reference.
      link = {
        id: menuData.id,
        title: menuData.title,
        type: menuData.type,
      };
    } else if (documents.office_actions.length) {
      // Display the default document, which is the most recent office action
      link = toTabLink(locale, "pdf", documents.office_actions[0]);
    } else {
      // Sometimes a patent application does not have office actions yet.
      setSidebarNoData(true);
    }
    // If we were unable to get a TabLink, this will no-op.
    manageTabs(link, from, "drawer");
  };

  /**
   * Some tabs display a list of documents.
   * This opens a document when the expand functionality is clicked on that type of tab.
   * @param doc
   * @param from
   */
  const handleOpenDocument = (doc: SNDocument, from?: string) => {
    const link = toTabLink(locale, "pdf", doc);
    manageTabs(link, from, "document");
  };

  const closeLeftPane = (index: number) => {
    if (active === index) {
      if (active === 0) {
        setActive(0);
      } else {
        setActive(active - 1);
      }
    } else if (index < active) {
      setActive(active - 1);
    }
    const updated = array1.filter(
      (value: TabLink, iindex: number) => iindex !== index
    );
    setLeftPane(updated);
    set_Array1(updated);
  };

  const closeRightPane = (index: number) => {
    if (active2 === index) {
      if (active2 === 0) {
        setActive2(0);
      } else {
        setActive2(active2 - 1);
      }
    } else if (index < active2) {
      setActive2(active2 - 1);
    }
    const updated = array2.filter(
      (value: TabLink, iindex: number) => iindex !== index
    );
    setRightPane(updated);
    setArray2(updated);
  };

  const handleCloseSidebarNoDataAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      // We want the user to explicitly exit this alert.
      return;
    }
    setSidebarNoData(false);
  };

  return (
    <React.Fragment>
      <Box id="document-viewer-box" sx={{ display: "flex" }}>
        <Sidebar
          onClick={handleDrawerClick}
          active={activeMenu}
          patent_app_data={patent_app_data}
          application_num={application_num}
        />
        <Box className="overFlow-hidden" component="main" sx={{ flexGrow: 1 }}>
          {sidebarNoData && (
            <Snackbar
              open={sidebarNoData}
              onClose={handleCloseSidebarNoDataAlert}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleCloseSidebarNoDataAlert}
                severity="error"
                sx={{ width: "100%" }}
              >
                <strong>
                  The current patent application has no documents of this type.
                </strong>
              </Alert>
            </Snackbar>
          )}
          <DragDropContext
            onDragEnd={handleDragEnd}
            onDragStart={(e) => {
              setTrackDrag(true);
            }}
          >
            <div
              className={`${array2.length > 0 ? "d-flex " : ""}`}
              style={{ gap: "6px" }}
            >
              <div
                className={`${array2.length > 0 ? "w-50" : "w-100"
                  } arrayDroppableDiv`}
              >
                <Droppable droppableId="list1" direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      className="topLinkDiv"
                      style={getListStyle(snapshot.isDraggingOver, true)}
                      ref={provided.innerRef}
                    >
                      {array1.map((tabLink: TabLink, index: number) => {
                        return (
                          <Draggable
                            key={tabLink.id}
                            draggableId={`list_${tabLink?.id.toString()}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {tabLink.document && (
                                  // <Tooltip
                                  //   title={tabLink.document?.document_code}
                                  //   arrow
                                  // >
                                  <span
                                    title={tabLink.document?.document_description}
                                    key={index}
                                    className={`linkTopDiv ${index === active
                                          ? "bg-white"
                                          : "bg-gray"
                                      } `}
                                  >
                                    <span
                                      onClick={() => {
                                        setActive(index);
                                      }}
                                    >
                                      <Typography
                                        noWrap
                                        className={`${index === active
                                            ? "ActiveTabTitle"
                                            : "Tabtitle"
                                          }`}
                                      >
                                        {createDocumentTitle(
                                          tabLink.document,
                                          locale
                                        )}
                                      </Typography>
                                    </span>
                                    <IconButton
                                      onClick={() => closeLeftPane(index)}
                                      size="small"
                                      {...(array1.length > 3
                                        ? {
                                          style: {
                                            marginLeft: 0,
                                            padding: 4,
                                          },
                                        }
                                        : { style: { marginLeft: 4 } })}
                                    >
                                      <CloseSharpIcon
                                        className="curserPointer"
                                        fontSize="inherit"
                                        style={{ margin: 0, fontSize: 14 }}
                                      />
                                    </IconButton>
                                  </span>
                                  // </Tooltip>
                                )}
                                {!tabLink.document && (
                                  // <Tooltip title={tabLink.title} arrow>
                                  <span
                                    title={tabLink.title}
                                    key={index}
                                    className={`linkTopDiv ${index === active
                                          ? "bg-white"
                                          : "bg-gray"
                                      } `}
                                  >
                                    <span
                                      onClick={() => {
                                        setActive(index);
                                      }}
                                    >
                                      <Typography
                                        noWrap
                                        className={`${index === active
                                            ? "ActiveTabTitle"
                                            : "Tabtitle"
                                          }`}
                                      >
                                        {tabLink.title}
                                      </Typography>
                                    </span>
                                    <IconButton
                                      onClick={() => closeLeftPane(index)}
                                      size="small"
                                      {...(array1.length > 3
                                        ? {
                                          style: {
                                            marginLeft: 0,
                                            padding: 4,
                                          },
                                        }
                                        : { style: { marginLeft: 4 } })}
                                    >
                                      <CloseSharpIcon
                                        className="curserPointer"
                                        fontSize="inherit"
                                        style={{ margin: 0, fontSize: 14 }}
                                      />
                                    </IconButton>
                                  </span>
                                  // </Tooltip>
                                )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {/* {provided.placeholder} */}
                    </div>
                  )}
                </Droppable>
                {array2.length === 0 && (
                  <Split
                    title={array1.length < 8 ? "SPLIT" : ""}
                    onClick={onSplit}
                  />
                )}
              </div>
              {array2.length > 0 && (
                <div className="w-50 arrayDroppableDiv">
                  <Droppable droppableId="list2" direction="horizontal">
                    {(provided, snapshot) => (
                      <div
                        style={getListStyle(snapshot.isDraggingOver, true)}
                        ref={provided.innerRef}
                        className="topLinkDiv"
                      >
                        {array2.map((tabLink: any, index: number) => {
                          return (
                            <Draggable
                              key={tabLink.id}
                              draggableId={`list1_${tabLink?.id.toString()}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {/* <Tooltip title={tabLink.title} arrow> */}
                                  <span
                                    title={tabLink.title}
                                    key={index}
                                    className={`linkTopDiv ${index === active2
                                          ? "bg-white"
                                          : "bg-gray"
                                      } `}
                                  >
                                    <span
                                      onClick={() => {
                                        setActive2(index);
                                      }}
                                    >
                                      <Typography
                                        noWrap
                                        className={`${index === active2
                                            ? "ActiveTabTitle"
                                            : "Tabtitle"
                                          }`}
                                      >
                                        {tabLink.title}
                                      </Typography>
                                    </span>
                                    <IconButton
                                      onClick={() => closeRightPane(index)}
                                      size="small"
                                      {...(array2.length > 2
                                        ? {
                                          style: {
                                            marginLeft: 0,
                                            padding: 4,
                                          },
                                        }
                                        : { style: { marginLeft: 4 } })}
                                    >
                                      <CloseSharpIcon
                                        className="curserPointer"
                                        fontSize="inherit"
                                        style={{ margin: 0, fontSize: 14 }}
                                      />
                                    </IconButton>
                                  </span>
                                  {/* </Tooltip> */}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {/* {provided.placeholder} */}
                      </div>
                    )}
                  </Droppable>
                  <Split
                    title={array2.length < 4 ? "UN-SPLIT" : ""}
                    onClick={onUnSplit}
                  />
                </div>
              )}
            </div>
            <Box
              className={`position-relative h-92 ${trackDrag ? "overFlow-hidden" : ""
                }`}
              sx={{ display: "flex", gap: "8px" }}
            >
              <Droppable droppableId="list3" direction="horizontal">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={` ${array2.length > 0
                        ? "w-50"
                        : trackDrag
                          ? "w-100 position-none"
                          : "w-279 position-absolute"
                      }`}
                  >
                    <div
                      className={`${array2.length > 0
                          ? "w-100"
                          : trackDrag
                            ? "w-100"
                            : "w-100"
                        }`}
                    >
                      <DocumentWrapper
                        tabs={array1}
                        documents={documents}
                        isActive={active}
                        handleDrawerClick={(clickProp: SidebarItem) =>
                          handleDrawerClick(clickProp, "array1")
                        }
                        handleOpenDocument={(
                          clickProp: SNDocument,
                          from?: string
                        ) => handleOpenDocument(clickProp, "array1")}
                      />
                    </div>

                    {/* {provided.placeholder} */}
                  </div>
                )}
              </Droppable>
              {array2.length > 0 ? (
                <Droppable droppableId="list4" direction="horizontal">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="w-50"
                    >
                      <DocumentWrapper
                        tabs={array2}
                        documents={documents}
                        isActive={active2}
                        handleDrawerClick={(clickProp: SidebarItem) =>
                          handleDrawerClick(clickProp, "array2")
                        }
                        handleOpenDocument={(
                          clickProp: SNDocument,
                          from?: string
                        ) => handleOpenDocument(clickProp, "array2")}
                      />
                    </div>
                  )}
                </Droppable>
              ) : (
                <Droppable droppableId="list5" direction="horizontal">
                  {(provided, snapshot) => (
                    <React.Fragment>
                      {trackDrag && (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={`w-15 h-full p-2 ml-auto`}
                          style={{
                            backgroundColor: snapshot.isDraggingOver
                              ? "lightblue"
                              : "#F6F6FC",
                          }}
                        >
                          {/* {provided.placeholder} */}
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </Droppable>
              )}
            </Box>
          </DragDropContext>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export { DocumentViewer };
