import React, { useState } from "react";
import {
  FormControl,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const SearchBar = ({
  value,
  onChange,
  columns,
  selectedColumn,
  setSelectedColumn,
}) => {
  const [showClearIcon, setShowClearIcon] = useState("none");

  const handleChange = (event) => {
    onChange(event.target.value);
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
  };

  const handleSelectChange = (event) => {
    setSelectedColumn(event.target.value);
  };

  const handleClick = () => {
    onChange("");
    setSelectedColumn("All");
    // TODO: Clear the search input
    console.log("clicked the clear icon...");
  };

  return (
    <div id="app">
      <FormControl sx={{ paddingLeft: 0 }}>
        <TextField
          name="search"
          size="small"
          variant="outlined"
          placeholder="Search..."
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Select
                  value={selectedColumn}
                  onChange={handleSelectChange}
                  defaultValue="All"
                  sx={{ height: "40px", border: "0px", marginRight: "5px" }}
                >
                  <MenuItem value="All">All</MenuItem>
                  {columns.map((column, index) => (
                    <MenuItem key={index} value={column.dataIndex}>
                      {column.title}
                    </MenuItem>
                  ))}
                </Select>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ display: showClearIcon }}
                onClick={handleClick}
              >
                <ClearIcon />
              </InputAdornment>
            ),
          }}
          value={value}
        />
      </FormControl>
    </div>
  );
};

export default SearchBar;
