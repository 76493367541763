import { Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const AppData = (props: any) => {
  return (
    <ListItem key={props.index} disablePadding>
      <ListItemText
        primary={
          <Typography color="text.primary" className="navItemsDataTitle">
            {props.title}
          </Typography>
        }
        secondary={
          <Typography
            sx={{ display: "inline" }}
            component="span"
            variant="body2"
            color="text.primary"
            className="navItemsData"
          >
            {props.data}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default AppData;
