import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";

const Split = (props: any) => {
  return (
    <Box className={`splitBox ${props.title ? "text" : "untext"}`}>
      <Button
        variant="outlined"
        startIcon={<SplitscreenIcon fontSize="small" />}
        sx={{ px: 2 }}
        onClick={props.onClick}
        // className={props.title === "" ? "margin0" : ""}
        size={props.title === "" ? "medium" : "small"}
      >
        {props.title}
      </Button>
    </Box>
  );
};

export default Split;
