import React, { useState, ChangeEvent, useCallback } from 'react';
import { Tab, Tabs, TextField, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone'
import { UploadDocketRequestV1 } from '../../api/patents';

interface UploadDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onUpload: (data: { files?: File[]; text?: string }) => void;
}

const UploadDialog: React.FC<UploadDialogProps> = ({ isOpen, onClose, onUpload }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [files, setFiles] = useState<File[]>([]);
    const [fileFieldText, setFileFieldText] = useState<string>('')
    const [text, setText] = useState<string>('');
    const [showInputWarning, setShowInputWarning] = useState(false)

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const handleFileFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFileFieldText(event.target.value)
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setFiles([...files, ...acceptedFiles]);
    }, [files])

    const handleRemoveFile = (index: number) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const handleSubmit = () => {
        if (!files.length && text.length === 0) {
            setShowInputWarning(true)
            return
        }
        setShowInputWarning(false)
        // Allow both file and text.
        const d: UploadDocketRequestV1 = {}
        if (files.length) {
            d.files = files
        }
        if (fileFieldText) {
            d.fileField = fileFieldText
        }
        if (text.length > 0) {
            d.nums = text
        }
        onUpload(d)
        closeDialog();
    };

    const closeDialog = () => {
        setFiles([])
        setFileFieldText('')
        setText('')
        onClose()
    }

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            maxWidth="lg"
        >
            <DialogTitle
                id="upload-dialog-title"
                className="dflexSpaceBetween"
            >
                <Box
                    className="dflexCenter"
                    sx={{ gap: 1 }}
                >
                    Upload Docket
                </Box>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    width: 500,
                }}
            >
                <Tabs value={tabIndex} onChange={handleTabChange} centered>
                    <Tab label="Upload File" />
                    <Tab label="Text Input" />
                </Tabs>
                <Box
                    sx={{ marginTop: 2, marginBottom: 3 }}
                >
                    {tabIndex === 0 && (
                        <Box
                            alignContent="center"
                            justifyContent="center"
                            display="flex"
                            flexDirection="column"
                        >
                            <TextField
                                label="Enter Field Name for File"
                                value={fileFieldText}
                                onChange={handleFileFieldChange}
                                size="small"
                                sx={{ marginBottom: 1 }}
                            />
                            <Box {...getRootProps({
                                className: 'dropzone',
                                style: {
                                    borderWidth: 2,
                                    borderRadius: 4,
                                    borderColor: '#eeeeee',
                                    borderStyle: 'dashed',
                                    padding: '20px',
                                    textAlign: 'center',
                                },
                            })}>
                                <div style={{ height: 20 }}></div>
                                <input {...getInputProps()} />
                                <Typography variant="body1" color="textSecondary">
                                    Drag &apos;n&apos; drop some files here, or click to select files
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    (Supported file types: csv, txt)
                                </Typography>
                                <ul>
                                    {files.map((file, index) => (
                                        <li key={file.name}>
                                            {file.name} - {file.size / 1000} KB
                                            <Button variant="text" color="error" onClick={() => handleRemoveFile(index)}>
                                                Remove
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        </Box>
                    )}
                    {tabIndex === 1 && (
                        <Box>
                            <Typography variant="body1" color="textSecondary">
                                Please paste patent application numbers in a comma separated list.
                            </Typography>
                            <TextField
                                label="Enter Text"
                                multiline
                                rows={4}
                                value={text}
                                onChange={handleTextChange}
                                fullWidth
                            />
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                {showInputWarning && (
                    <Typography variant='body1' color="red">
                        Provide some data to upload.
                    </Typography>
                )}
                <Button onClick={closeDialog} color="primary">
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    autoFocus
                >
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UploadDialog;
