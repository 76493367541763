
import HistoryIcon from "@mui/icons-material/History";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";

interface IModal {
  AppFileHistory: any;
  Figures: any;
  PatentRef: any;
}

const AppFileHistoryTabs = ["ALL", " CLAIMS", "RESPONSES"];

const AppFileHistoryVariables = {
  appFileHistory: "Application File History",
};

const modalData: IModal = {
  AppFileHistory: {
    icon: <HistoryIcon />,
    title: "Application File History",
    subTitle1: "Working documents",
    subTitle2: "Office action",
    Table1rows: [
      {
        submission_date: "01/01/2022",
        name: "Young Tang",
      },
      {
        submission_date: "01/02/2022",
        name: "Young Tang",
      },
    ],
    Table2rows: [
      {
        submission_date: "01/01/2022",
        name: "Young Tang",
      },
      {
        submission_date: "02/02/2022",
        name: "Young Tang",
      },
      {
        submission_date: "03/02/2022",
        name: "Young Tang",
      },
      {
        submission_date: "04/02/2022",
        name: "Young Tang",
      },
    ],
  },
  Figures: {
    icon: <ImageSearchIcon />,
    title: "Figures",
    subTitle1: "On current page",
    subTitle2: "In this document",
    Table1rows: [
      {
        page: "Current",
        name: "Figure 8.1",
      },
      {
        page: "Current",
        name: "Figure 8.2a",
      },
    ],
    Table2rows: [
      {
        page: "Page 3, Paragraph 1",
        name: "Figure 8.1",
      },
      {
        page: "Current",
        name: "Figure 8.2",
      },
      {
        page: "Page 3, Paragraph 1",
        name: "Figure 8.3",
      },
      {
        page: "Current",
        name: "Figure 8.4",
      },
    ],
  },
  PatentRef: {
    icon: <AssignmentReturnIcon />,
    title: "Patent Reference",
    subTitle1: "On current page",
    subTitle2: "In this document",
    Table1rows: [
      {
        page: "Current",
        name: "US11520669X99",
      },
      {
        page: "Current",
        name: "US87596328F0",
      },
    ],
    Table2rows: [
      {
        page: "Page 3, Paragraph 1",
        name: "US87596328F0",
      },
      {
        page: "Current",
        name: "US93641995678TX4009",
      },
      {
        page: "Page 2, Paragraph 1",
        name: "US87596328F0",
      },
      {
        page: "Current",
        name: "US93641995678TX4009",
      },
    ],
  },
};

export {
  AppFileHistoryTabs,
  AppFileHistoryVariables,
  modalData,
};
