import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  let navigate = useNavigate();
  return (
    <div className="pageNotFound">
      <div className="pageNotFoundDiv">
        <Typography variant="h3">Page Not Found!</Typography>
        <Button onClick={() => navigate("/home")}>Back to Home page</Button>
      </div>
    </div>
  );
}
