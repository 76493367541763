import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { AccountCircle, DeleteOutline } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Container,
  Tooltip,
  Alert,
  Snackbar,
  Tabs,
  Tab,
  Checkbox,
  Select,
  OutlinedInput,
  SelectChangeEvent,
  InputLabel,
  ListItemText,
  FormControl,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CircularProgress } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import DataTable from "../../components/Table/Table";
import { useUser, UserSession } from "../../userContext";
import { logout } from "../../api/auth";
import { DocketResp, Docket, Patent, CustomPatent } from "../../common/patent";
import { setApplicationNumber, setSNLocale } from "../../common/sessionStorage";
import UploadDialog from "./uploadDialog";
import {
  UploadDocketRequestV1,
  uploadCustomDocket,
  getJobStatus,
  getJobPolling,
} from "../../api/patents";
import SearchBar from "../../components/SearchBar";
import DeleteModal from "../../components/DeleteModal";
import StatusComponent from "../../components/StatusComponent";

const API_URL = process.env.REACT_APP_API_SERVER_URL || "";

export default function OfficeActionDocket() {
  const [docket, setDocket] = useState<Docket>([]);
  const [loading, setLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const { user, setUser } = useUser();
  const [locale, setLocale] = useState("");
  const [localJobIds, setLocalJobIds] = useState<string[]>([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [uploadModalStatus, setUploadModalStatus] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("All");

  // Tracks all unique customer numbers in the docket
  const [allCustomerNumbers, setAllCustomerNumbers] = useState<string[]>([]);
  // Tracks the currently selected customer numbers
  const [selectedCustomerNums, setSelectedCustomerNums] = useState<string[]>(
    []
  );
  const [applicationNumbers, setApplicationNumbers] = useState<string[]>([]);
  // Flag to note whether to go back to showing all customer numbers
  const [selectAllCustomerNums, setSelectAllCustomerNums] = useState(
    allCustomerNumbers.length === selectedCustomerNums.length
  );

  const open = Boolean(anchor);

  let navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      // We want the user to explicitly exit this alert.
      return;
    }
    setError(null);
  };

  const handleLogout = async () => {
    setUser({ ...user, isAuthenticated: false });

    try {
      await logout();
    } catch (err) {
      console.warn(`failed to log out: ${err}`);
    }
    sessionStorage.clear();
    navigate("/");
  };

  const onSuccess = async (jobIDs:string[]) => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    getData(user, true, jobIDs?.length > 0 ? jobIDs : localJobIds);
    setOpenUploadModal(false);
  };

  const handleDocketUpload = async (
    data: UploadDocketRequestV1
  ): Promise<void> => {
    try {
      setUploadLoading(true);
      await uploadCustomDocket(data, onSuccess, false)
    } catch (err: any) {
      setUploadModalStatus(`${err}`);
    }
  };

  const handleOpenUploadModal = (open: boolean) => {
    setOpenUploadModal(open);
  };

  const handleCloseUploadModalStatus = () => {
    setUploadModalStatus("");
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const renderUploadModalStatus = (uploadModalStatus: string) => {
    return (
      <Snackbar
        open={uploadModalStatus !== ""}
        onClose={handleCloseUploadModalStatus}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseUploadModalStatus}
          severity="error"
          sx={{ width: "100%" }}
        >
          <strong>{uploadModalStatus}</strong>
        </Alert>
      </Snackbar>
    );
  };

  // retrieve the docket data
  const getData = useCallback(
    async (User: UserSession, callFetchData: boolean, jobIDs:string[]) => {
      try {
        // Keeping this in case we move back to docket numbers.
        // let docket_num = ""
        // if (user.docket_numbers) {
        //   docket_num = user.docket_numbers.join(",")
        // }
        let customer_num = "";
        if (user.customer_numbers) {
          customer_num = user.customer_numbers.join(",");
        }

        let useCustomDocket = false;
        let resp;
        setLoading(true)
        if (tabIndex !== 0) {
          useCustomDocket = true;
          resp = await axios.get(`${API_URL}/v1/docket/custom`, {
            headers: {},
            params: {
              customer_num,
              custom_docket: useCustomDocket,
              sort_by: "created_at DESC",
            },
            withCredentials: true,
          });
        } else {
          resp = await axios.get(`${API_URL}/v1/docket`, {
            headers: {},
            params: {
              // docket_num,
              customer_num,
              custom_docket: useCustomDocket,
              sort_by: "last_submission_date DESC",
            },
            withCredentials: true,
          });
        }

        const data: DocketResp = resp.data;
        if (resp.status !== 200) {
          if (resp.status === 401) {
            // Session likely expired or was invalidated, reflect that in the session
            setUser({
              isAuthenticated: false,
            });
          }

          const errMsg =
            data.errors?.join("; ") ||
            `unknown error received with status: ${resp.status}`;
          throw new Error(errMsg);
        }

        if (!data.resources) {
          setError("no resources were returned");
        }
        setDocket(data.resources ?? []);
        if (callFetchData) {
          setTimeout(() => {
            fetchData(data.resources ?? [], false,jobIDs);
          }, 100);
        }
        setLoading(false);
      } catch (err) {
        console.error(`failed to retrieve docket: ${err}`);

        setDocket([]);
        setError(
          "Something went wrong retrieving the docket. Please try again later."
        );
        setLoading(false);
      }
    },
    [setUser, tabIndex]
  );

  const fetchData = async (
    docketData: CustomPatent[],
    callGetData: boolean,
    jobIDs:string[]
  ) => {
    try {
      let tempjobIDs = [...jobIDs];
      const tempDocket = [...docketData];
      const data = await getJobPolling(tempjobIDs);
      if (data && data.length > 0) {

        for (let index = 0; index < tempjobIDs.length; index++) {
          const element = tempjobIDs[index];
          const fIndex = data.findIndex((e)=>e.jobId === element);
          if(fIndex > -1 && data[fIndex].progress ===100)
          {
            tempjobIDs = tempjobIDs.filter((e)=>e!== element)
          }
        }
     

        try {
          if (docketData && docketData.length > 0) {
            for (let i = 0; i < tempDocket.length; i++) {
              const isIndex = data.findIndex(
                (e) => {
                  return tempDocket[i].application_num === e.appNum}
              );
              if (isIndex > -1) {
                if (data[isIndex].progress === 100) {
                  delete tempDocket[i].enableLoading;
                } else {
                  tempDocket[i].enableLoading = true;
                }
                tempDocket[i].progress = data[isIndex].progress;
              } else {
                delete tempDocket[i].progress;
                delete tempDocket[i].enableLoading;
              }
            }
            setDocket(tempDocket);
            setTimeout(() => {
              fetchData(tempDocket, true,tempjobIDs);
            }, 10000);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        if (callGetData) {
          setTimeout(() => {
            getData(user, false, jobIDs?.length > 0 ? jobIDs : localJobIds);
          }, 100);
        }
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  useEffect(() => {
    const storedJobIds = localStorage.getItem('jobIds');
    if(storedJobIds){
      setLocalJobIds(JSON.parse(storedJobIds))
    }
  },[localJobIds]);

  // session storage and locale
  useEffect(() => {
    sessionStorage.clear();
    setLocale(navigator.language);
    setSNLocale(navigator.language);
  }, []);

  // retrieve the data
  useEffect(() => {
    getData(user, true, localJobIds && localJobIds?.length > 0 ? localJobIds: []);
  }, [user, tabIndex]);

  // gather the unique customer numbers in the docket
  useEffect(() => {
    const customerNums: { [key: string]: string } = {};
    for (let row of docket) {
      customerNums[row.customer_num] = row.customer_num;
    }
    const nums = Object.keys(customerNums);
    setAllCustomerNumbers(nums);
    setSelectedCustomerNums(nums);
  }, [docket]);

  const redirect = (row: CustomPatent) => {
    if (
      row.enableLoading === undefined ||
      row.progress === undefined ||
      Number(row.progress) === 100
    ) {
      if (row.application_num) {
        setApplicationNumber(row.application_num);
      }
      navigate("/home");
    }
  };

  const handleCustomerNumSelected = (
    event: SelectChangeEvent<typeof selectedCustomerNums>
  ) => {
    // Stop a potential sort.
    event.stopPropagation();

    const {
      target: { value },
    } = event;

    if (value.includes("selectAll")) {
      // Toggle "Select All"
      setSelectAllCustomerNums(!selectAllCustomerNums);

      // If "Select All" is checked, set all items to selected
      // Otherwise, clear all selections
      setSelectedCustomerNums(selectAllCustomerNums ? [] : allCustomerNumbers);
      return;
    }
    const selectedNums = typeof value === "string" ? value.split(",") : value;
    // On autofill we get a stringified value.
    setSelectedCustomerNums(selectedNums);
    setSelectAllCustomerNums(selectedNums.length === allCustomerNumbers.length);
  };

  const renderCustomerNumTitle = (title: string, dataIndex: string) => {
    if (allCustomerNumbers.length <= 1) {
      return <Typography variant="inherit">{title}</Typography>;
    }

    return (
      <Tooltip
        title={title}
        enterDelay={4000}
        enterNextDelay={2000}
        placement="top-end"
      >
        <FormControl sx={{ width: "125px" }}>
          <InputLabel id="customer-num-multi-checkbox-label">
            {title}
          </InputLabel>
          <Select
            labelId="customer-num-multi-checkbox-label"
            id="customer-num-multi-checkbox"
            multiple
            value={selectedCustomerNums}
            onChange={handleCustomerNumSelected}
            input={<OutlinedInput label={title} />}
            renderValue={(selected) => selected.join(", ")}
            size="small"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                },
              },
            }}
          >
            <MenuItem value="selectAll">
              <Checkbox checked={selectAllCustomerNums} />
              Select All
            </MenuItem>
            {allCustomerNumbers.map((num) => (
              <MenuItem key={num} value={num}>
                <Checkbox checked={selectedCustomerNums.indexOf(num) > -1} />
                <ListItemText primary={num} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
    );
  };

  const columns = [
    {
      title: "Application Number",
      dataIndex: "application_num",
      onClick: (item: Patent) => redirect(item),
      render: (item: Patent) => (
        <Typography variant="inherit" fontWeight={600}>
          {item.application_num}
        </Typography>
      ),
      canSort: true,
    },
    {
      title: "Status",
      dataIndex: "application_status_description_text",
      width: 220,
      onClick: (item: Patent) => redirect(item),
      render: (item: Patent) =>
        item?.application_status_description_text ?? "-",
      canSort: true,
    },
    {
      title: "Notification Date",
      dataIndex: "last_submission_date",
      onClick: (item: Patent) => redirect(item),
      render: (item: Patent) => {
        const m = moment(item.last_submission_date).locale(locale).utc();
        const dateStr = m.format("DD/MM/YYYY");
        if (item?.last_submission_date !== "0001-01-01T00:00:00Z") {
          return (
            <Tooltip
              title={moment.localeData().longDateFormat("L")}
              enterDelay={1000}
              enterNextDelay={1000}
              placement="bottom-start"
            >
              <Typography variant="inherit" fontWeight={600}>
                {dateStr}
              </Typography>
            </Tooltip>
          );
        } else {
          return "-";
        }
      },
      canSort: true,
    },
    {
      title: "Attorney Docket #",
      dataIndex: "docket_num",
      onClick: (item: Patent) => redirect(item),
      render: (item: Patent) => (
        <Typography variant="inherit" fontWeight={600}>
          {item.docket_num ?? "="}
        </Typography>
      ),
    },
    {
      title: "Customer Number",
      renderTitle: renderCustomerNumTitle,
      dataIndex: "customer_num",
      onClick: (item: Patent) => redirect(item),
      render: (item: Patent) => (
        <Typography variant="inherit" fontWeight={600}>
          {item.customer_num ?? "-"}
        </Typography>
      ),
    },
    {
      title: "Status",
      dataIndex: "jobStatus",
      render: (item: Patent) => {
        return <StatusComponent item={item} onSuccess={onSuccess} />;
      },
    },
    {
      // The holding column for the delete icon
      title: "Action",
      dataIndex: "delete",
      render: (item: Patent) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            justifyContent: "center",
          }}
        >
          <DeleteModal
            onConfirm={async (handleClose) => {
              await axios
                .delete(`${API_URL}/v1/docket/${item.application_num}`, {
                  withCredentials: true,
                })
                .then((res: any) => {
                  if (res.status === 200) {
                    handleClose();
                    setDocket(
                      docket.filter(
                        (patent) =>
                          patent.application_num !== item.application_num
                      )
                    );
                    localStorage.removeItem('jobIds');
                  }
                })
                .catch((error: any) => {
                  console.log(error);
                });
            }}
            message={"Are you sure you want to delete?"}
            title={"Delete"}
          >
            <DeleteOutline sx={{ cursor: "pointer" }} />
          </DeleteModal>
        </Box>
      ),
    },
    {
      // The holding column for the Progress icon
      title: "",
      dataIndex: "progress",
      render: (item: CustomPatent) => {
        if (item.progress && Number(item.progress) < 100) {
          return (
            <Box position="relative" display="flex" alignItems="center">
              <CircularProgress
                variant="determinate"
                value={Math.round(Number(item.progress))}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >{`${Math.round(Number(item.progress))}%`}</Typography>
              </Box>
            </Box>
          );
        } else if (uploadLoading && item.enableLoading === true) {
          return (
            <Box position="relative" display="flex" alignItems="center">
              <CircularProgress />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >{`0%`}</Typography>
              </Box>
            </Box>
          );
        } else {
          return "";
        }
      },
    },
    {
      // The holding column for the arrow icon
      title: "",
      dataIndex: "action",
      onClick: redirect,
      render: (item: Patent) => (
        <IconButton onClick={() => redirect(item)}>
          <ArrowForwardIosIcon />
        </IconButton>
      ),
    },
  ];

  interface columnKeys {
    application_num: string;
    application_status_description_text: string;
    customer_num: string;
    docket_num: string;
    last_submission_date: string;
  }

  // renders the data table depending on the tab we are viewing
  const renderTabs = (
    loading: boolean,
    columns: any,
    docket: Docket,
    tabIndex: number
  ) => {
    if (loading) {
      return (
        <div className="loading" style={{ minHeight: "44vh" }}>
          <CircularProgress disableShrink />
        </div>
      );
    }

    // Filter customer numbers based on selected
    const tableData = docket.filter((patent: Patent) => {
      if (selectedCustomerNums.includes(patent.customer_num)) {
        const searchLower = search ? search.toLowerCase() : "";
        if (selectedColumn === "All") {
          let found = false;
          for (let index = 0; index < columns.length; index++) {
            const column = columns[index];
            const colDataLower = patent[column.dataIndex as keyof columnKeys]
              ? patent[column.dataIndex as keyof columnKeys]?.toLowerCase()
              : "";
            if (colDataLower && colDataLower.indexOf(searchLower) > -1) {
              found = true;
              break;
            }
          }
          return found;
        } else if (selectedColumn) {
          const colDataLower = patent[selectedColumn as keyof columnKeys]
            ? patent[selectedColumn as keyof columnKeys]?.toLowerCase()
            : "";
          return colDataLower && colDataLower.indexOf(searchLower) > -1;
        }
      }
      return false;
    });

    if (tabIndex !== 0) {
      return (
        <DataTable
          sx={{ px: 2 }}
          columns={columns}
          dataSource={tableData}
          pagination={true}
          showHeader={true}
          enableSort={true}
          defaultRowsPerPage={10}
          defaultOrder="desc"
          defaultOrderBy="created_at"
        />
      );
    }
    return (
      <DataTable
        sx={{ px: 2 }}
        columns={columns.filter(
          (e: any) =>
            e.dataIndex !== "delete" &&
            e.dataIndex !== "progress" &&
            e.dataIndex !== "jobStatus"
        )}
        dataSource={docket}
        pagination={true}
        showHeader={true}
        enableSort={true}
        defaultRowsPerPage={10}
        defaultOrder="desc"
        defaultOrderBy="created_at"
      />
    );
  };

  return (
    <div>
      <Box
        sx={{
          p: 2,
          mb: 4,
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          className="hoverNone logoutbtn"
          onClick={handleClick}
          color="inherit"
        >
          <AccountCircle
            fontSize={"large"}
            sx={{ alignSelf: "flex-end" }}
            className="logoutIcon"
          />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchor}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
      <Container maxWidth="lg" sx={{ mb: 4 }}>
        {error && (
          <Snackbar
            open={error !== null && error.length > 0}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity="error"
              sx={{ width: "100%" }}
            >
              <strong>{error}</strong>
            </Alert>
          </Snackbar>
        )}
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className="tableTitle">Office Action Docket</Typography>
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              justifyContent: "space-between",
            }}
          >
            <SearchBar
              value={search}
              onChange={setSearch}
              columns={columns.filter(
                (e: any) =>
                  e.dataIndex !== "delete" &&
                  e.dataIndex !== "progress" &&
                  e.dataIndex !== "action" &&
                  e.dataIndex !== "jobStatus"
              )}
              selectedColumn={selectedColumn}
              setSelectedColumn={setSelectedColumn}
            />
            <Button
              variant="contained"
              sx={{ px: 3 }}
              onClick={() => handleOpenUploadModal(true)}
            >
              + Add
            </Button>
          </Box>
        </Box>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          sx={{ marginLeft: 1 }}
        >
          <Tab label="Docket" />
          <Tab label="Custom Docket" />
        </Tabs>
        <TableContainer
          className="office-action-docket"
          component={Paper}
          sx={{ borderRadius: "16px" }}
        >
          {renderTabs(loading, columns, docket, tabIndex)}
        </TableContainer>
      </Container>
      <UploadDialog
        isOpen={openUploadModal}
        onClose={() => handleOpenUploadModal(false)}
        onUpload={handleDocketUpload}
      />
      {uploadModalStatus && renderUploadModalStatus(uploadModalStatus)}
    </div>
  );
}
