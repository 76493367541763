import { useState, useEffect, useMemo, useCallback } from "react";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
    Avatar,
    Box,
    IconButton,
    Link,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";

import PopupModal from "../../components/PopupModal";
import { TabLink } from "./domain";
import {
    OrganizedDocuments,
    OrganizedDocumentsToAll,
    SNDocument,
} from "../../common/document";
import { DocumentLinks, FigureLinks } from "../../common/references";
import moment from "moment";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { getActivatedLinksForDocument } from '../../api/activatedlinks'

const API_URL = process.env.REACT_APP_API_SERVER_URL || "";

type ModalData = {
    // All documents in the patent application history.
    AppFileHistory: {
        icon: any;
        title: "Application File History";
        subSection1: "Working documents";
        subSection2?: "";
        documents: OrganizedDocuments;
        getFirstSection: (index: number, data: ModalData) => any[];
        getSecondSection?: (index: number, data: ModalData) => any[];
    };
    // Figures provided in the application.
    Figures: {
        icon: any;
        title: "Figures";
        subSection1: "On current page";
        subSection2?: "In this document";
        documents: FigureLinks;
        getFirstSection: (index: number, data: ModalData) => any[];
        getSecondSection?: (index: number, data: ModalData) => any[];
    };
    // References found in the current document.
    // This is typically an existing (published) patent document.
    // Activated links in the document also serve to display document references.
    References: {
        icon: any;
        title: "Patent Reference";
        subSection1: "On current page";
        subSection2?: "In this document";
        documents: DocumentLinks;
        getFirstSection: (index: number, data: ModalData) => any[];
        getSecondSection?: (index: number, data: ModalData) => any[];
    };
};

const FileDocument = ({
    documents,
    e,
    index,
    handleOpenDocument,
}: {
    documents: OrganizedDocuments;
    e: TabLink;
    index: number;
    handleOpenDocument: (doc: SNDocument, from?: string) => void;
}) => {
    const columns: any = [
        {
            title: "Filing Date",
            dataIndex: "filingDate",
            render: (item: any) => (
                <Box className="dflexStart">
                    {item.submission_date ? (
                        <Link sx={{ textDecoration: "none", mr: 1 }}>
                            {moment(item.submission_date).format("DD/MM/YYYY")}
                        </Link>
                    ) : (
                        <>
                            <Link sx={{ textDecoration: "none", mr: 1 }}>{item.page}</Link>
                            <FindInPageIcon className="findInPageIcon" />
                        </>
                    )}
                </Box>
            ),
        },
        {
            title: "Description",
            dataIndex: "description",
            render: (item: SNDocument) => {
                if (item.document_code) {
                    return (
                      <Link sx={{ textDecoration: 'none' }}>{item.document_code}</Link>
                    )
                  } else {
                    return (
                      <Link sx={{ textDecoration: 'none' }}>
                        {item.publication_number}
                      </Link>
                    )
                  }
            }
        },
        {
            // This opens the document in a new tab.
            title: "",
            dataIndex: "action",
            render: (item: SNDocument, index: number) => (
                <IconButton
                    onClick={() => {
                        setActiveModal("");
                        return handleOpenDocument(item, "modal");
                    }}
                >
                    <OpenInFullIcon />
                </IconButton>
            ),
        },
    ];
    const [activeModal, setActiveModal] = useState("");
      // Start out loading
    const [loading, setLoading] = useState<Boolean>(true)
    // Have an error state
    const [appErr, setAppErr] = useState<string>('')
    const [modalData, setModalData] = useState<ModalData>({
        AppFileHistory: {
            icon: <HistoryIcon />,
            title: "Application File History",
            subSection1: "Working documents",
            documents: documents,
            getFirstSection: (_: number, data: ModalData): any[] => {
                // if (e.document) {
                //   return GetDocumentsForCode(
                //     data.AppFileHistory.documents,
                //     e.document.document_code
                //   );
                // }
                return OrganizedDocumentsToAll(data.AppFileHistory.documents);
            },
        },
        Figures: {
            icon: <ImageSearchIcon />,
            title: "Figures",
            subSection1: "On current page",
            subSection2: "In this document",
            documents: {}, // TODO:
            getFirstSection: (index: number, data: ModalData): any[] => {
                return documents.figures;
            },
            getSecondSection: (index: number, data: ModalData): any[] => {
                return [];
            },
        },
        References: {
            icon: <AssignmentReturnIcon />,
            title: "Patent Reference",
            subSection1: "On current page",
            subSection2: "In this document",
            documents: {}, // TODO:
            getFirstSection: (index: number, data: ModalData): any[] => {
                return [];
            },
            getSecondSection: (index: number, data: ModalData): any[] => {
                return [];
            },
        },
    });

    useEffect(() => {
        setModalData((prevModalData) => ({
            AppFileHistory: Object.assign(
                {
                    documents: OrganizedDocumentsToAll(documents),
                },
                prevModalData.AppFileHistory
            ),
            Figures: Object.assign(
                {
                    documents: {},
                },
                prevModalData.Figures
            ),
            References: Object.assign(
                {
                    documents: {},
                },
                prevModalData.References
            ),
        }));
    }, [documents]); // eslint-disable-line

    const fileUrl = useMemo(
        () => ({
            url: e.type === "pdf" ? `${API_URL}/v1/documents/${e.id}/file` : e.file,
            withCredentials: true,
        }),
        [e]
    );

    // Retrieves the activated Links data.
    const getActivatedLinksData = useCallback(getActivatedLinksForDocument, [])

    /**
     * Renders the floating buttons that pop up modals of the document types.
     * @returns
     */
    const renderFloatingButtons = (): JSX.Element => {
        // NOTE: setActiveModal must match the ModalData fields.
        return (
            <div
                style={{
                    position: "absolute",
                    top: "12vh",
                    left: "2vh",
                    zIndex: 2,
                }}
            >
                <Box
                    className="buttonHoverEffectBox light-gray curserPointer"
                    onClick={() => {
                        setActiveModal("AppFileHistory");
                    }}
                >
                    <Avatar className="buttonHover buttonHoverText1 ">
                        <HistoryIcon />
                    </Avatar>
                </Box>
                <Box
                    className="buttonHoverEffectBox light-blue curserPointer"
                    onClick={() => {
                        setActiveModal("Figures");
                    }}
                >
                    <Avatar className="buttonHover buttonHoverText2">
                        <ImageSearchIcon />
                    </Avatar>
                </Box>
                <Box
                    className="buttonHoverEffectBox light-green curserPointer"
                    onClick={async () => {
                        await getActivatedLinksData(e.id)
                          .then((data: any) => {
                            if (data && data !== undefined) {
                              setActiveModal('References')
                              setModalData((prevModalData) => ({
                                AppFileHistory: prevModalData.AppFileHistory,
                                Figures: prevModalData.Figures,
                                References: {
                                  ...prevModalData.References,
                                  getSecondSection: (): any[] => {
                                    const newData: {
                                      publication_number: string
                                      page: string
                                    }[] = []
                                    Object.keys(data).forEach((key) => {
                                      if (
                                        data[key].publication_number &&
                                        data[key as keyof typeof data].publication_number
                                          .length > 0
                                      ) {
                                        for (
                                          let index = 0;
                                          index <
                                          data[key as keyof typeof data].publication_number
                                            .length;
                                          index++
                                        ) {
                                          const element =
                                            data[key as keyof typeof data]
                                              .publication_number[index]
                                          newData.push({
                                            publication_number: element,
                                            page: key,
                                          })
                                        }
                                      }
                                    })
            
                                    return newData
                                  },
                                },
                              }))
                            }
                          })
                          .catch((err:string) => {
                            setAppErr(err)
                          })
                          .finally(() => {
                            setLoading(false)
                          })
                      }}
                >
                    <Avatar className="buttonHover buttonHoverText3">
                        <AssignmentReturnIcon />
                    </Avatar>
                </Box>
            </div>
        );
    };

    const renderPopupModal = (
        activeModal: string,
        modalData: ModalData
    ): JSX.Element => {
        const mode = modalData[activeModal as keyof typeof modalData];
        return (
            <PopupModal
                icon={mode?.icon || null}
                title={mode?.title || "Unknown"}
                subSection1={mode?.subSection1 || "No data"}
                subSection2={mode?.subSection2 || ""}
                open={activeModal !== ""}
                onClose={() => setActiveModal("")}
                table1data={
                    activeModal !== "" ? mode?.getFirstSection(index, modalData) : []
                }
                table2data={
                    activeModal !== ""
                        ? mode?.getSecondSection
                            ? mode?.getSecondSection(index, modalData)
                            : []
                        : []
                }
                columns={columns || []}
            />
        );
    };

    return (
        <Box sx={{ position: "relative" }}>
            <Box className="pdfMainBox minHeightDiv">
                <Box
                    className={"pdfMainDiv"}
                    sx={{
                        bgcolor: "background.default",
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <iframe
                        title={fileUrl.url}
                        src={fileUrl.url}
                        style={{}}
                        width="100%"
                    />
                </Box>
                {renderPopupModal(activeModal, modalData)}
            </Box>
            {renderFloatingButtons()}
        </Box>
    );
};

export default FileDocument;
