import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, CircularProgress, Container, Snackbar } from '@mui/material';

import { DocumentViewer } from './documentviewer';
import { OrganizeDocuments, OrganizedDocuments, SNDocument } from '../../common/document';
import { getApplicationNumber, setCurrentPatent } from '../../common/sessionStorage';
import { AggregatedPatentRespV1 } from '../../common/patent';
import { getPatentAppData, getPatentAppDocuments } from '../../api/document';


// TODO: Make all the documents and app data context
// That way we can pass this data as props.
export const Wrapper = () => {
    let navigate = useNavigate()

    // Start out loading
    const [loading, setLoading] = useState<Boolean>(true)

    // Have an error state
    const [appErr, setAppErr] = useState<string>("")
    const [documentErr, setDocumentErr] = useState<string>("")

    const [application_num] = useState<string>(
        getApplicationNumber()
    )

    // Track patent app data
    const [appData, setAppData] = useState<AggregatedPatentRespV1>({
        patent: {
            application_num: "",
            patent_num: "",
            publication_num: "",
            application_status_date: "",
            application_status_description_text: "",
            application_type_category: "",
            created_at: "",
            customer_num: "",
            docket_num: "",
            filing_date: "",
            group_art_unit_num: "",
            invention_subject_matter_category: "",
            invention_title: "",
            last_submission_date: "",
            national_stage_indicator: "",
            updated_at: "",
        },
        examiner_details: {
            application_num: application_num,
            first_name: "",
            last_name: "",
            phone_number: "",
        },
        inventor_details: [],
    })

    // Track patent document data
    const [documents, setDocuments] = useState<OrganizedDocuments>({
        office_actions: [],
        claims: [],
        responses: [],
        specification: [],
        figures: [],
        other: [],
    })

    // Check for the application number
    useEffect(() => {
        if (!application_num) {
            navigate("/docket")
        }
    }, [application_num, navigate])

    // Retrieves the aggregated patent application data.
    const getApplicationData = useCallback(getPatentAppData, []);

    // Retrieve the application data.
    const getAppDocuments = useCallback(getPatentAppDocuments, [])

    // Fetch the aggregated patent application data
    useEffect(() => {
        getApplicationData(application_num).then(data => {
            if (data && data !== undefined) {
                setCurrentPatent(data)
                setAppData(data)
            } else {
                console.warn("No aggregated patent application data was retrieved")
            }
        }).catch(err => {
            setAppErr(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [application_num, getApplicationData])

    // Fetch the patent application file history documents.
    useEffect(() => {
        getAppDocuments(application_num).then(docs => {
            if (docs && docs !== undefined) {
                // Convert the slice of documents into something easily usable by the document viewer.
                const orgDocs = organizeDocuments(docs)
                setDocuments(orgDocs)
            } else {
                console.warn("No documents were retrieved")
            }
        }).catch(err => {
            setDocumentErr(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [getAppDocuments, application_num])

    /**
     * Translates a slice of SNDocuments into an organized schema for components to use.
     * @param documents
     * @returns
     */
    const organizeDocuments = (documents: SNDocument[]): OrganizedDocuments => {
        return OrganizeDocuments(documents)
    }

    const handleCloseDocumentSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setDocumentErr("")
    }

    const handleCloseAppSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAppErr("")
    }

    return (
        <div>
            {appErr && (
                <Snackbar
                    open={(appErr !== null && appErr.length > 0)}
                    onClose={handleCloseAppSnackbar}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert onClose={handleCloseAppSnackbar} severity="error" sx={{ width: '100%' }}>
                        <strong>{appErr}</strong>
                    </Alert>
                </Snackbar>
            )}
            {documentErr && (
                <Snackbar
                    open={(documentErr !== null && documentErr.length > 0)}
                    onClose={handleCloseDocumentSnackbar}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert onClose={handleCloseDocumentSnackbar} severity="error" sx={{ width: '100%' }}>
                        <strong>{documentErr}</strong>
                    </Alert>
                </Snackbar>
            )}
            {loading && (
                <Container sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}>
                    <div className="loading">
                        <CircularProgress disableShrink />
                    </div>
                </Container>
            )}
            {(!appErr && !documentErr && !loading) && (
                <DocumentViewer
                    application_num={application_num}
                    patent_app_data={appData}
                    documents={documents}
                />
            )}
        </div>

    )
}