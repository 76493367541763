import { useState } from "react";

import { ClearUser, UserSession } from "../userContext";

type LoginResponse = {
    errors?: string[];
};

type LoginCredentials = {
    email: string;
    password: string;
};

type MeResponse = {
    resource?: UserSession;
    errors?: string[];
}

export interface ErrUnauthorized {
    message: string
    status: 401
}

const API_URL = process.env.REACT_APP_API_SERVER_URL || "";

export const useLogin = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const login = async (email: string, password: string): Promise<UserSession | null> => {

        // Simple validation
        if (!simpleEmailValidation(email) || !simplePasswordValidation(password)) {
            console.warn('failed email or password validation')

            setError("Invalid email or password")
            return null;
        }

        setLoading(true);
        setError(null);

        try {
            await makeLogin(email, password)
        } catch (err) {
            console.warn(`Login failed: ${err}`)
            setLoading(false)
            setError("Login failed")
            throw new Error("Login failed")
        }

        let userData: UserSession | null
        try {
            userData = await getMe()
            if (!userData) {
                throw new Error("missing user data in response")
            }
        } catch (err) {
            console.warn(`failed to retrieve user data: ${JSON.stringify(err)}`)
            setLoading(false)
            setError("Login failed")
            throw new Error("Failed to retrieve user data")
        }
        setLoading(false)
        setError(null)
        return userData
    };

    return { loading, error, login };
};

const makeLogin = async (email: string, password: string): Promise<void> => {
    const form: LoginCredentials = {
        email,
        password,
    }

    try {
        const res = await fetch(`${API_URL}/auth/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(form),
        });

        if (!res.body) {
            console.log(`Login failed unexpectedly: ${res.status}`)
            throw new Error("Login failed unexpectedly")
        }

        const data: LoginResponse = await res.json();

        if (!data) {
            console.log(`Login failed unexpectedly: ${res.status}`)
            throw new Error("Login failed unexpectedly");
        }

        if (!res.ok) {
            if (!data.errors || !data.errors.length) {
                console.log(`Login failed with unknown error: ${res.status}`)
                throw new Error("Login failed with unknown error")
            }

            console.info(`Login failed: ${data.errors.join("; ")}`)

            if (res.status === 401) {
                throw new Error("Invalid email or password")
            }
            console.log(`Login failed with code: ${res.status}`)
            throw new Error("Login failed")
        }
    } catch (err: any) {
        console.warn(`error message: ${err}`)
        throw new Error(`failed to make login request: ${err}`)
    }
}

const simpleEmailValidation = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+$/;
    return regex.test(email);
}

const simplePasswordValidation = (password: string): boolean => {
    return password.length >= 8
}

export const logout = async () => {
    try {
        const res = await fetch(`${API_URL}/auth/logout`, {
            method: "POST",
            credentials: "include",
        });

        if (res.status !== 200) {
            const data = await res.text()
            throw new Error(`Logout failed unexpectedly: ${data}`)
        }
    } catch (err: any) {
        console.error(`logout failed: ${err}`)
    }
    ClearUser()
}

export const getMe = async (): Promise<UserSession | null> => {
    try {
        const res = await fetch(`${API_URL}/auth/me`, {
            method: "GET",
            credentials: "include",
        });

        if (!res.body) {
            throw new Error("Get me failed unexpectedly")
        }

        const data: MeResponse = await res.json();

        if (!data) {
            throw new Error("Get me failed unexpectedly with no data");
        }

        if (res.ok && data.resource) {
            let r = data.resource
            r.isAuthenticated = true
            return r
        } else if (res.ok && !data.resource) {
            console.error("Get me failed: responded with 2xx, but no user data")
            throw new Error("Get me failed unexpectedly")
        }

        if (!data.errors || !data.errors.length) {
            throw new Error("Get me failed with unknown error")
        }

        console.info(`Get me failed: ${data.errors.join("; ")}`)

        if (res.status === 401) {
            const err: ErrUnauthorized = { message: "User not authorized", status: 401 }
            throw err
        }

        throw new Error("Get me failed")
    } catch (err: any) {
        // Not used in the component yet.
        const checkErr: ErrUnauthorized = err
        if (checkErr.status === 401) {
            throw err
        }

        console.warn(`error message: ${err}`)
        throw new Error("Failed to get user data")
    }
};
