import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "../src/styles/styles.css";
import ForgotPassword from "./pages/forgotPassword";
import Login from "./pages/login";
import NotFound from "./pages/notFound";
import OfficeActionDocket from "./pages/docket/officeActionDocket";
import { useUser } from "./userContext";
import PrivateRoute from "./components/PrivateRoute";
import { Wrapper } from "./pages/documentviewer/wrapper";
import {
  Box,
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const theme = createTheme({
  palette: {
    primary: {
      main: "#3F51B5",
    },
  },
});

const MainApp = () => {
  const { user, loading } = useUser();

  return (
    <ThemeProvider theme={theme}>
      <div className="h-full bodyBg overFlowAuto">
        {loading ? (
          <Box
            className="loading"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              height: "100vh",
            }}
          >
            <CircularProgress disableShrink />
          </Box>
        ) : (
          <Routes>
            <Route
              path="/"
              element={
                user.isAuthenticated ? (
                  <Navigate to="/docket" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route path="/login" element={<Login />} />
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <Wrapper />
                </PrivateRoute>
              }
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/docket"
              element={
                <PrivateRoute>
                  <OfficeActionDocket />
                </PrivateRoute>
              }
            />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        )}
      </div>
    </ThemeProvider>
  );
};

export default MainApp;
