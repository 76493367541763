import { Fragment, useEffect, useState } from "react";
import { Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {
  AppFileHistoryTabs,
  AppFileHistoryVariables,
} from "../common/commonData";
import CRS from "../components/CRS";
import {
  OrganizedDocuments,
  OrganizedDocumentsToAll,
  SNDocument,
} from "../common/document";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box style={{ backgroundColor: "red" }}>{children}</Box>
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AppFileHistory = ({
  documents,
  handleOpenDocument,
}: {
  documents: OrganizedDocuments;
  handleOpenDocument: (doc: SNDocument, from?: string) => void;
}) => {
  const [value, setValue] = useState(0);

  const [allDocuments, setAllDocuments] = useState<SNDocument[]>([]);

  useEffect(() => {
    setAllDocuments(OrganizedDocumentsToAll(documents));
  }, [documents]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Fragment>
      <Box sx={{ p: 3, bgcolor: "background.default" }}>
        <Typography component="h1" variant="h5" className="pageTitle">
          {AppFileHistoryVariables.appFileHistory}
        </Typography>
        <Box sx={{ mt: 4, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange}>
            {AppFileHistoryTabs.map((item: string, index: number) => {
              return (
                <Tab
                  label={item}
                  key={item}
                  {...a11yProps(index)}
                  className={value === index ? "tabsTitleActive" : "tabsTitle"}
                />
              );
            })}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <CRS
            documents={allDocuments}
            handleOpenDocument={handleOpenDocument}
            document_type="" // all
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CRS
            documents={documents.claims}
            handleOpenDocument={handleOpenDocument}
            document_type="claims"
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CRS
            documents={documents.responses}
            handleOpenDocument={handleOpenDocument}
            document_type="responses"
          />
        </TabPanel>
      </Box>
    </Fragment>
  );
};

export default AppFileHistory;
