import React, { useState, useEffect } from "react";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { CircularProgress, IconButton, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import moment from "moment";

import DataTable from "./Table/Table";
import { TabLinkType } from "../pages/documentviewer/domain";
import { getSNLocale } from "../common/sessionStorage";
import { SNDocument } from "../common/document";

/**
 * The component for a claims, responses, or specification viewer, which does not represent a specific document.
 * This component instead provides the list of each aforementioned document type found in the application file history.
 * For example, if the document_type is "claims", then we will display a table/list of all claims documents found in the file history.
 */
const CRS = ({
  title,
  document_type,
  document_code,
  handleOpenDocument,
  documents,
}: {
  title?: string;
  document_type: TabLinkType | "";
  document_code?: string;
  handleOpenDocument: (doc: SNDocument, from?: string) => void;
  documents: SNDocument[];
}) => {
  const [locale] = useState<string>(getSNLocale());
  const [loading, setLoading] = useState<Boolean>(true);

  useEffect(() => {
    if (documents.length) {
      setLoading(false);
    }
  }, [documents]);

  const columns = [
    {
      title: "Filing Date",
      dataIndex: "filingDate",
      render: (item: SNDocument) =>
        moment(item.submission_date).locale(locale).utc().format("DD/MM/YYYY"),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (item: SNDocument) => (
        <Link sx={{ textDecoration: "none" }}>{item.document_code}</Link>
      ),
    },
    {
      // This opens the document in a new tab.
      title: "",
      dataIndex: "action",
      render: (item: SNDocument, index: number) => (
        <IconButton
          onClick={() => {
            return handleOpenDocument(item);
          }}
        >
          <OpenInFullIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Box
        sx={{
          p: 3,
          bgcolor: "background.default",
          overflow: "scroll",
          maxHeight: "100vh",
        }}
      >
        {title && (
          <Typography
            sx={{ mb: 3 }}
            component="h1"
            variant="h5"
            className="pageTitle"
          >
            {title}
          </Typography>
        )}

        <TableContainer component={Paper} sx={{ borderRadius: "16px" }}>
          {loading ? (
            <div className="loading">
              <CircularProgress disableShrink />
            </div>
          ) : (
            <DataTable
              sx={{ minWidth: 550, px: 2 }}
              columns={columns}
              dataSource={documents}
            />
          )}
        </TableContainer>
      </Box>
    </React.Fragment>
  );
};

export default CRS;
