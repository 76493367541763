import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from '@mui/material/InputAdornment';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import CardContent from "@mui/material/CardContent";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

import { useUser } from "../userContext";
import { useLogin } from "../api/auth";
import Footer from "../components/Footer";

export default function SignIn() {
  let navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const { login, loading, error } = useLogin();
  const { user, setUser } = useUser();

  React.useEffect(() => {
    if (user.isAuthenticated) {
      navigate("/docket")
    } else {
      sessionStorage.clear()
    }
  }, [user, navigate])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // Prevent reload
    event.preventDefault();

    try {
      const resp = await login(email, password);
      if (resp) {
        setUser(resp)
      }
    } catch (err) {
      console.error(`unexpectedly failed to log in: ${err}`)
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          paddingTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" className="primaryColor">
          SENSUS
        </Typography>
        <Card
          variant="outlined"
          sx={{ borderRadius: "12px" }}
          className="cardStyle"
        >
          <CardContent>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 0, px: 3, py: 2 }}
            >
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                className="inputPadding"
                autoFocus
                variant="standard"
                autoComplete="off"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                className="inputPadding"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="off"
                variant="standard"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container sx={{ mt: 1 }}>
                <Grid item xs style={{ display: "flex", alignItems: "center" }}>
                  {(error && error.length) && <Typography variant="body2" color="red" className="fs-14">{error}</Typography>}
                </Grid>
                <Grid item>
                  <Button
                    style={{ textTransform: "capitalize" }}
                    variant="text"
                    onClick={() => navigate("/forgot-password")}
                    className="linkDecoNone forgetHover fs-14"
                  >
                    Forgot password
                  </Button>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 0, height: 50 }}
                className="buttonPrimary"
                disabled={loading}
              >
                {loading ? (
                  <div className="loading" style={{ height: "inherit" }}>
                    <CircularProgress disableShrink size={35} />
                  </div>
                ) : "LOG IN"}

              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Footer />
    </Container>
  );
}
